import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBoxes, faQuestionCircle, faHeart } from '@fortawesome/free-solid-svg-icons';

export const inventoryIcon: IconProp = faBoxes;
export const missingIcon: IconProp = faQuestionCircle;
export const wantedIcon: IconProp = faHeart;

export const inventoryColor = '$color-space-blue';
export const missingColor = '$color-space-light-red';
export const wantedColor = '$color-space-gold';
