import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface EmptyWantListProps {
    isOwnProfile: boolean;
    user?: string;
}

const EmptyWantList: React.FC<EmptyWantListProps> = ({ isOwnProfile, user }) => {
    return (
        <Container className="text-center py-5">
            <Row className="justify-content-center">
                <Col md={8} lg={6}>
                    <div className="bg-space-dark p-4 rounded-lg shadow-lg">
                        <FontAwesomeIcon icon={faSearch} size="4x" className="text-space-blue mb-4" />
                        <h2 className="text-space-white mb-3">
                            {isOwnProfile ? "You don't have a want list yet" : `${user} doesn't have a want list yet`}
                        </h2>
                        <p className="text-space-gray mb-4">
                            {isOwnProfile ? (
                                <>
                                    Start building your want list to keep track of the cards you&apos;re looking for!
                                    <br />
                                    Cards you mark as wanted in your collection will appear here.
                                </>
                            ) : (
                                "This user hasn't added any cards to their want list yet."
                            )}
                        </p>
                        {isOwnProfile && (
                            <Link to="/collections/my/edit" className="text-decoration-none">
                                <Button variant="outline-space-blue" className="mt-3">
                                    Go to Your Collection
                                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                                </Button>
                            </Link>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default EmptyWantList;
