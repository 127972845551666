import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert, Card, Row, Col } from 'react-bootstrap';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '../graphql/generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Skeleton from './layout/Skeleton';


const UserProfile: React.FC = () => {
    const { data, loading, error, refetch } = useGetUserProfileQuery();
    const [updateProfile] = useUpdateUserProfileMutation();
    const [handle, setHandle] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [updateMessage, setUpdateMessage] = useState('');

    useEffect(() => {
        if (data?.user?.handle) {
            setHandle(data.user.handle);
        }
    }, [data]);

    if (loading) {
        return (
            <Container className="py-5">
                <Skeleton height="40px" width="30%" className="mb-4" />
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <Skeleton height="24px" width="50%" className="mb-2" />
                                <Skeleton height="20px" width="70%" className="mb-3" />
                            </Col>
                            <Col md={6}>
                                <Skeleton height="24px" width="50%" className="mb-2" />
                                <Skeleton height="20px" width="70%" className="mb-3" />
                            </Col>
                        </Row>
                        <Skeleton height="24px" width="50%" className="mb-2" />
                        <Skeleton height="38px" width="30%" className="mb-3" />
                    </Card.Body>
                </Card>
            </Container>
        );
    }
    if (error) return <p>Error: {error.message}</p>;

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await updateProfile({
                variables: { input: { handle } }
            });
            if (result.data?.updateUserProfile?.user) {
                setUpdateMessage('Profile updated successfully');
                setIsEditing(false);
                refetch(); // Refetch user data to update the displayed handle
            } else if (result.data?.updateUserProfile?.errors) {
                setUpdateMessage(result.data.updateUserProfile.errors.map(error => error.message).join(', '));
            }
        } catch {
            setUpdateMessage('An error occurred while updating the profile');
        }
    };

    return (
        <Container className="py-5">
            <h1 className="mb-4">Profile</h1>
            {updateMessage && <Alert variant={updateMessage.includes('successfully') ? 'success' : 'danger'}>{updateMessage}</Alert>}
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="fw-bold">Name</Form.Label>
                                <p>{data?.user?.name || 'N/A'}</p>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label className="fw-bold">Email</Form.Label>
                                <p>{data?.user?.email || 'N/A'}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Handle</Form.Label>
                            {!data?.user?.handle && !isEditing ? (
                                <div>
                                    <p className="text-info">
                                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                        You need to set a handle to share your collections.
                                    </p>
                                    <Button variant="primary" onClick={() => setIsEditing(true)}>Set Handle</Button>
                                </div>
                            ) : (
                                <>
                                    {isEditing ? (
                                        <>
                                            <Form.Control
                                                type="text"
                                                value={handle}
                                                onChange={(e) => setHandle(e.target.value.toLowerCase())}
                                                onBlur={() => {
                                                    const validHandle = handle.replace(/[^a-z0-9_-]/g, '');
                                                    if (validHandle !== handle) {
                                                        setHandle(validHandle);
                                                    }
                                                }}
                                                placeholder="Enter handle (lowercase letters, numbers, dash, underscore)"
                                                isInvalid={!/^[a-z0-9_-]+$/.test(handle)}
                                            />
                                            {!/^[a-z0-9_-]+$/.test(handle) && (
                                                <Form.Control.Feedback type="invalid">
                                                    Handle must contain only lowercase letters, numbers, dash, or underscore.
                                                </Form.Control.Feedback>
                                            )}
                                        </>
                                    ) : (
                                        <p className="fs-5 text-primary">@{data?.user?.handle}</p>
                                    )}
                                    {!isEditing && (
                                        <Button variant="outline-secondary" className="mt-2" onClick={() => setIsEditing(true)}>
                                            Change Handle
                                        </Button>
                                    )}
                                </>
                            )}
                        </Form.Group>
                        {isEditing && (
                            <div>
                                <Button variant="primary" type="submit" className="me-2">
                                    Save Handle
                                </Button>
                                <Button variant="outline-secondary" onClick={() => {
                                    setIsEditing(false);
                                    setHandle(data?.user?.handle || '');
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default UserProfile;
