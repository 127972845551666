import React, { useState } from 'react';
import { UpdateUserVariantInventoryMutation } from '../../../graphql/generated/graphql';
import InventoryField from './input_fields/InventoryField';
import MissingField from './input_fields/MissingField';
import WantedField from './input_fields/WantedField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inventoryIcon, missingIcon, wantedIcon } from '../InventoryIcons';


interface InventoryEditProps {
    variantId: number;
    initialInventory: number;
    initialMissing: { count: number | null; isDefault: boolean };
    initialWanted: number;
    onInventoryUpdate: (variantId: number, field: 'inventory' | 'missing' | 'wanted', newValue: number | null, oldValue: number | null) => Promise<UpdateUserVariantInventoryMutation>;
}

const InventoryEdit: React.FC<InventoryEditProps> = ({
    variantId,
    initialInventory,
    initialMissing,
    initialWanted,
    onInventoryUpdate
}) => {
    const [inventory, setInventory] = useState(initialInventory);
    const [missing, setMissing] = useState(initialMissing);
    const [wanted, setWanted] = useState(initialWanted);

    const handleUpdate = async (field: 'inventory' | 'missing' | 'wanted', newValue: number | null, oldValue: number | null) => {
        try {
            const result = await onInventoryUpdate(variantId, field, newValue, oldValue);
            if (result.updateUserVariantInventory?.userVariantInventory) {
                const updatedInventory = result.updateUserVariantInventory.userVariantInventory;
                setInventory(updatedInventory.inventory);
                setMissing(updatedInventory.missing);
                setWanted(updatedInventory.wantedCount);
            }
        } catch (error) {
            console.error('Error updating inventory:', error);
            // Revert to previous values on error
            setInventory(inventory);
            setMissing(missing);
            setWanted(wanted);
        }
    };

    return (
        <div className="inventory-edit-container">
            <div className="inventory-item column inventory">
                <label>
                    <FontAwesomeIcon icon={inventoryIcon} className="icon" /> Have
                </label>
                <InventoryField
                    initialValue={inventory}
                    onUpdate={(newValue) => handleUpdate('inventory', newValue, inventory)}
                />
            </div>
            <div className="inventory-item column missing">
                <label>
                    <FontAwesomeIcon icon={missingIcon} className="icon" /> Need
                </label>
                <MissingField
                    initialValue={missing}
                    onUpdate={(newValue) => handleUpdate('missing', newValue, missing?.count)}
                />
            </div>
            <div className="inventory-item column wanted">
                <label>
                    <FontAwesomeIcon icon={wantedIcon} className="icon" /> Want
                </label>
                <WantedField
                    initialValue={wanted}
                    onUpdate={(newValue) => handleUpdate('wanted', newValue, wanted)}
                />
            </div>
        </div>
    );

};

export default InventoryEdit;
