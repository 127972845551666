import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inventoryIcon, missingIcon, wantedIcon } from './InventoryIcons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InventoryEdit from './inventory_edit/InventoryEdit';
import { VariantColumn } from '../collection/InventoryCardTable';
import { UserCardInventory, UserVariantInventory } from '../../graphql/generated/graphql';
import CardHover from '../card/CardHover';
import { UpdateUserVariantInventoryMutation } from '../../graphql/generated/graphql';


interface InventoryCardTableRowProps {
    cardInventory: UserCardInventory;
    isEditMode: boolean;
    isOwner: boolean;
    onInventoryUpdate: (variantId: number, field: 'inventory' | 'missing' | 'wanted', newValue: number | null, oldValue: number | null) => Promise<UpdateUserVariantInventoryMutation>;
    onCardSelect?: (card: UserCardInventory) => void;
    variantColumns: VariantColumn[];
    className?: string;
}

export const InventoryCardTableRow: React.FC<InventoryCardTableRowProps> = ({
    cardInventory,
    isEditMode,
    isOwner,
    onInventoryUpdate,
    onCardSelect,
    variantColumns,
    className
}) => {
    const handleInventoryChange = async (
        variantId: number,
        field: "inventory" | "missing" | "wanted",
        newValue: number | null,
        oldValue: number | null
    ): Promise<UpdateUserVariantInventoryMutation> => {
        return onInventoryUpdate(variantId, field, newValue, oldValue);
    };

    const handleCardClick = () => {
        if (onCardSelect) {
            onCardSelect(cardInventory);
        }
    };

    const renderInventoryDetails = (inventoryDetails: UserVariantInventory | null) => {
        if (!inventoryDetails) return '-';

        if (isOwner && isEditMode) {
            return (
                <div className="inventory-edit">
                    <InventoryEdit
                        variantId={inventoryDetails.variantId}
                        initialInventory={inventoryDetails.inventory}
                        initialMissing={inventoryDetails.missing}
                        initialWanted={inventoryDetails.wantedCount}
                        onInventoryUpdate={handleInventoryChange}
                    />
                </div>
            );
        } else {
            return (
                <div className="inventory-details d-flex align-items-center">
                    <span className="inventory-item inventory me-2">
                        <FontAwesomeIcon icon={inventoryIcon} className="icon" />
                        {inventoryDetails.inventory}
                    </span>
                    {isOwner && (inventoryDetails.missing.count > 0 || inventoryDetails.wantedCount > 0) && (
                        <>
                            <div className="vr mx-2"></div>
                            <div className="d-flex align-items-center">
                                {inventoryDetails.missing.count > 0 && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`missing-${inventoryDetails.variantId}`}>Missing</Tooltip>}
                                    >
                                        <span className="inventory-item missing me-2">
                                            <FontAwesomeIcon icon={missingIcon} className="icon" />
                                            {inventoryDetails.missing.count}
                                        </span>
                                    </OverlayTrigger>
                                )}
                                {inventoryDetails.wantedCount > 0 && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`wanted-${inventoryDetails.variantId}`}>Wanted</Tooltip>}
                                    >
                                        <span className="inventory-item wanted">
                                            <FontAwesomeIcon icon={wantedIcon} className="icon" />
                                            {inventoryDetails.wantedCount}
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </>
                    )}
                </div>
            );
        }
    };

    return (
        <tr onClick={handleCardClick} className={className}>
            <td>{cardInventory.cardDetails.cardNumber}</td>
            <td>
                <CardHover cardId={cardInventory.id} showImageOnly={true}>
                    <span>{cardInventory.cardDetails.name}</span>
                </CardHover>
            </td>
            {variantColumns.map(({ type, countKey }) => {
                const inventoryDetails = cardInventory[countKey as keyof UserCardInventory] as UserVariantInventory | null;
                return (
                    <td key={type}>
                        {renderInventoryDetails(inventoryDetails)}
                    </td>
                );
            })}
        </tr>
    );
};
