import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: string; output: string; }
};

/** Types of arenas */
export enum ArenaTypeEnum {
  Ground = 'GROUND',
  Space = 'SPACE'
}

/** Types of aspects */
export enum AspectTypeEnum {
  Aggression = 'AGGRESSION',
  Command = 'COMMAND',
  Cunning = 'CUNNING',
  Heroism = 'HEROISM',
  Vigilance = 'VIGILANCE',
  Villainy = 'VILLAINY'
}

/** A card */
export type Card = {
  __typename?: 'Card';
  arena?: Maybe<ArenaTypeEnum>;
  /** Array of aspect types */
  aspects?: Maybe<Array<AspectTypeEnum>>;
  backText?: Maybe<Scalars['String']['output']>;
  cardSet: CardSet;
  cardType: CardTypeEnum;
  /** Array of card variant types */
  cardVariants?: Maybe<Array<CardVariant>>;
  cost: Scalars['Int']['output'];
  frontText: Scalars['String']['output'];
  hp?: Maybe<Scalars['Int']['output']>;
  hyperspaceCardNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  power?: Maybe<Scalars['Int']['output']>;
  rarity: RarityTypeEnum;
  standardCardNumber?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  traits?: Maybe<Array<Scalars['String']['output']>>;
  unique: Scalars['Boolean']['output'];
};

/** The connection type for Card. */
export type CardConnection = {
  __typename?: 'CardConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CardEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Card>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CardDetails = {
  __typename?: 'CardDetails';
  cardNumber: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rarity: Scalars['String']['output'];
};

/** An edge in a connection. */
export type CardEdge = {
  __typename?: 'CardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Card>;
};

/** A card set */
export type CardSet = {
  __typename?: 'CardSet';
  cards: CardConnection;
  code: CardSetCodeEnum;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


/** A card set */
export type CardSetCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum CardSetCodeEnum {
  Shd = 'SHD',
  Sor = 'SOR'
}

/** The connection type for CardSet. */
export type CardSetConnection = {
  __typename?: 'CardSetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CardSetEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CardSet>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CardSetEdge = {
  __typename?: 'CardSetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CardSet>;
};

/** Types of card types */
export enum CardTypeEnum {
  Base = 'BASE',
  Event = 'EVENT',
  Leader = 'LEADER',
  Unit = 'UNIT',
  Upgrade = 'UPGRADE'
}

/** A variant of a card */
export type CardVariant = {
  __typename?: 'CardVariant';
  card: Card;
  cardNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: Image;
  variantType: CardVariantTypeEnum;
};

/** Types of card variants */
export enum CardVariantTypeEnum {
  Foil = 'FOIL',
  Hyperspace = 'HYPERSPACE',
  HyperspaceFoil = 'HYPERSPACE_FOIL',
  Showcase = 'SHOWCASE',
  Standard = 'STANDARD'
}

/** Autogenerated input type of CreateOffer */
export type CreateOfferInput = {
  buyerHandle: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offerItems: Array<OfferItemInput>;
};

/** Autogenerated return type of CreateOffer. */
export type CreateOfferPayload = {
  __typename?: 'CreateOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  offer?: Maybe<Offer>;
};

export type Error = {
  __typename?: 'Error';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type Image = {
  __typename?: 'Image';
  backUrl?: Maybe<Scalars['String']['output']>;
  frontUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type MissingCount = {
  __typename?: 'MissingCount';
  count: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createOffer?: Maybe<CreateOfferPayload>;
  rescindOffer?: Maybe<RescindOfferPayload>;
  updateCollectionSharing?: Maybe<UpdateCollectionSharingPayload>;
  updateUserProfile?: Maybe<UpdateUserProfilePayload>;
  updateUserVariantInventory?: Maybe<UpdateUserVariantInventoryPayload>;
  updateWantListSharing?: Maybe<UpdateWantListSharingPayload>;
};


export type MutationCreateOfferArgs = {
  input: CreateOfferInput;
};


export type MutationRescindOfferArgs = {
  input: RescindOfferInput;
};


export type MutationUpdateCollectionSharingArgs = {
  input: UpdateCollectionSharingInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateUserVariantInventoryArgs = {
  input: UpdateUserVariantInventoryInput;
};


export type MutationUpdateWantListSharingArgs = {
  input: UpdateWantListSharingInput;
};

export type Offer = {
  __typename?: 'Offer';
  buyer: User;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  offerItems: Array<OfferItem>;
  seller: User;
  status: OfferStatusEnum;
  totalPrice: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

/** The connection type for Offer. */
export type OfferConnection = {
  __typename?: 'OfferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OfferEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Offer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OfferEdge = {
  __typename?: 'OfferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Offer>;
};

export type OfferItem = {
  __typename?: 'OfferItem';
  cardVariant: CardVariant;
  id: Scalars['ID']['output'];
  offer: Offer;
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
};

export type OfferItemInput = {
  price: Scalars['Float']['input'];
  quantity: Scalars['Int']['input'];
  variantId: Scalars['ID']['input'];
};

export enum OfferStatusEnum {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Rescinded = 'RESCINDED'
}

export enum OfferTypeEnum {
  Received = 'RECEIVED',
  Sent = 'SENT'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  card: Card;
  cardSet: CardSet;
  cardSets: CardSetConnection;
  cards: CardConnection;
  sharedWantLists: SharedWantListConnection;
  user?: Maybe<User>;
  userCardSetInventory: UserCardInventoryConnection;
  userOffers: OfferConnection;
  userSharingSettings: UserSharingSettings;
  userWantList: UserWantItemConnection;
  users: UserConnection;
};


export type QueryCardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCardSetArgs = {
  cardSetCode: CardSetCodeEnum;
};


export type QueryCardSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySharedWantListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserCardSetInventoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cardSetCode: CardSetCodeEnum;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userHandle?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserOffersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  type: OfferTypeEnum;
};


export type QueryUserWantListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userHandle?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Types of rarities */
export enum RarityTypeEnum {
  Common = 'COMMON',
  Legendary = 'LEGENDARY',
  Rare = 'RARE',
  Special = 'SPECIAL',
  Uncommon = 'UNCOMMON'
}

/** Autogenerated input type of RescindOffer */
export type RescindOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
};

/** Autogenerated return type of RescindOffer. */
export type RescindOfferPayload = {
  __typename?: 'RescindOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  offer?: Maybe<Offer>;
};

export enum RoleEnum {
  Admin = 'ADMIN',
  User = 'USER'
}

export type SharedWantList = {
  __typename?: 'SharedWantList';
  id: Scalars['Int']['output'];
  publiclyShared: Scalars['Boolean']['output'];
  user: User;
};

/** The connection type for SharedWantList. */
export type SharedWantListConnection = {
  __typename?: 'SharedWantListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SharedWantListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SharedWantList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SharedWantListEdge = {
  __typename?: 'SharedWantListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SharedWantList>;
};

/** Autogenerated input type of UpdateCollectionSharing */
export type UpdateCollectionSharingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareWithAll: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UpdateCollectionSharing. */
export type UpdateCollectionSharingPayload = {
  __typename?: 'UpdateCollectionSharingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateUserProfile */
export type UpdateUserProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  handle: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateUserProfile. */
export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  user?: Maybe<User>;
};

/** Autogenerated input type of UpdateUserVariantInventory */
export type UpdateUserVariantInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
  newValue?: InputMaybe<Scalars['Int']['input']>;
  variantId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateUserVariantInventory. */
export type UpdateUserVariantInventoryPayload = {
  __typename?: 'UpdateUserVariantInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  userVariantInventory?: Maybe<UserVariantInventory>;
};

/** Autogenerated input type of UpdateWantListSharing */
export type UpdateWantListSharingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shareWithAll: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UpdateWantListSharing. */
export type UpdateWantListSharingPayload = {
  __typename?: 'UpdateWantListSharingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Error>;
  success: Scalars['Boolean']['output'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  handle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  roles: Array<RoleEnum>;
};

export type UserCardInventory = {
  __typename?: 'UserCardInventory';
  cardDetails: CardDetails;
  foilInventory?: Maybe<UserVariantInventory>;
  hyperspaceFoilInventory?: Maybe<UserVariantInventory>;
  hyperspaceInventory?: Maybe<UserVariantInventory>;
  id: Scalars['ID']['output'];
  showcaseInventory?: Maybe<UserVariantInventory>;
  standardInventory?: Maybe<UserVariantInventory>;
};

/** The connection type for UserCardInventory. */
export type UserCardInventoryConnection = {
  __typename?: 'UserCardInventoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserCardInventoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserCardInventory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserCardInventoryEdge = {
  __typename?: 'UserCardInventoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserCardInventory>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserSharingSettings = {
  __typename?: 'UserSharingSettings';
  collectionSharedWithAll: Scalars['Boolean']['output'];
  wantListSharedWithAll: Scalars['Boolean']['output'];
};

export type UserVariantInventory = {
  __typename?: 'UserVariantInventory';
  id: Scalars['Int']['output'];
  inventory: Scalars['Int']['output'];
  missing: MissingCount;
  publishedWantedCount: Scalars['Int']['output'];
  variantId: Scalars['Int']['output'];
  wantedCount: Scalars['Int']['output'];
};

export type UserWantCardDetails = {
  __typename?: 'UserWantCardDetails';
  /** Array of aspect types */
  aspects?: Maybe<Array<AspectTypeEnum>>;
  cardSetId: Scalars['ID']['output'];
  cardSetName: Scalars['String']['output'];
  cardType: Scalars['String']['output'];
  fullCardNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rarity: Scalars['String']['output'];
};

export type UserWantItem = {
  __typename?: 'UserWantItem';
  cardDetails: UserWantCardDetails;
  id: Scalars['ID']['output'];
  variantCounts: Array<VariantCount>;
};

/** The connection type for UserWantItem. */
export type UserWantItemConnection = {
  __typename?: 'UserWantItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserWantItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserWantItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserWantItemEdge = {
  __typename?: 'UserWantItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserWantItem>;
};

export type VariantCount = {
  __typename?: 'VariantCount';
  count: Scalars['Int']['output'];
  publishedCount: Scalars['Int']['output'];
  variantId: Scalars['ID']['output'];
  variantType: CardVariantTypeEnum;
};

export type CreateOfferMutationVariables = Exact<{
  input: CreateOfferInput;
}>;


export type CreateOfferMutation = { __typename?: 'Mutation', createOffer?: { __typename?: 'CreateOfferPayload', offer?: { __typename?: 'Offer', id: string, totalPrice: number, status: OfferStatusEnum, createdAt: string, seller: { __typename?: 'User', id: number, name: string }, buyer: { __typename?: 'User', id: number, name: string }, offerItems: Array<{ __typename?: 'OfferItem', id: string, quantity: number, price: number, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum } }> } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type RescindOfferMutationVariables = Exact<{
  offerId: Scalars['ID']['input'];
}>;


export type RescindOfferMutation = { __typename?: 'Mutation', rescindOffer?: { __typename?: 'RescindOfferPayload', offer?: { __typename?: 'Offer', id: string, status: OfferStatusEnum } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateCollectionSharingMutationVariables = Exact<{
  input: UpdateCollectionSharingInput;
}>;


export type UpdateCollectionSharingMutation = { __typename?: 'Mutation', updateCollectionSharing?: { __typename?: 'UpdateCollectionSharingPayload', success: boolean, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile?: { __typename?: 'UpdateUserProfilePayload', user?: { __typename?: 'User', id: number, handle?: string | null } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateUserVariantInventoryMutationVariables = Exact<{
  variantId: Scalars['Int']['input'];
  field: Scalars['String']['input'];
  newValue?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UpdateUserVariantInventoryMutation = { __typename?: 'Mutation', updateUserVariantInventory?: { __typename?: 'UpdateUserVariantInventoryPayload', userVariantInventory?: { __typename?: 'UserVariantInventory', id: number, inventory: number, variantId: number, wantedCount: number, publishedWantedCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type UpdateWantListSharingMutationVariables = Exact<{
  input: UpdateWantListSharingInput;
}>;


export type UpdateWantListSharingMutation = { __typename?: 'Mutation', updateWantListSharing?: { __typename?: 'UpdateWantListSharingPayload', success: boolean, errors: Array<{ __typename?: 'Error', field: string, message: string }> } | null };

export type GetCardQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCardQuery = { __typename?: 'Query', card: { __typename?: 'Card', id: string, name: string, subtitle?: string | null, cardType: CardTypeEnum, rarity: RarityTypeEnum, cost: number, power?: number | null, hp?: number | null, arena?: ArenaTypeEnum | null, aspects?: Array<AspectTypeEnum> | null, traits?: Array<string> | null, frontText: string, backText?: string | null, unique: boolean, keywords?: Array<string> | null, image?: { __typename?: 'Image', frontUrl?: string | null, backUrl?: string | null, id: string } | null, cardVariants?: Array<{ __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, image: { __typename?: 'Image', id: string, frontUrl?: string | null, backUrl?: string | null } }> | null } };

export type GetCardSetQueryVariables = Exact<{
  cardSetCode: CardSetCodeEnum;
}>;


export type GetCardSetQuery = { __typename?: 'Query', cardSet: { __typename?: 'CardSet', id: string, name: string, code: CardSetCodeEnum, cards: { __typename?: 'CardConnection', nodes?: Array<{ __typename?: 'Card', id: string, name: string, subtitle?: string | null, standardCardNumber?: string | null, hyperspaceCardNumber?: string | null, cost: number, rarity: RarityTypeEnum, power?: number | null, hp?: number | null, cardType: CardTypeEnum, arena?: ArenaTypeEnum | null, aspects?: Array<AspectTypeEnum> | null, traits?: Array<string> | null, keywords?: Array<string> | null, image?: { __typename?: 'Image', frontUrl?: string | null, backUrl?: string | null, id: string } | null } | null> | null } } };

export type GetCardSetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardSetsQuery = { __typename?: 'Query', cardSets: { __typename?: 'CardSetConnection', nodes?: Array<{ __typename?: 'CardSet', id: string, code: CardSetCodeEnum, name: string, description?: string | null, imageUrl?: string | null } | null> | null } };

export type GetCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardsQuery = { __typename?: 'Query', cards: { __typename?: 'CardConnection', nodes?: Array<{ __typename?: 'Card', id: string, name: string, subtitle?: string | null, cardType: CardTypeEnum, rarity: RarityTypeEnum, cost: number, power?: number | null, hp?: number | null, arena?: ArenaTypeEnum | null, aspects?: Array<AspectTypeEnum> | null, traits?: Array<string> | null, frontText: string, unique: boolean } | null> | null } };

export type GetSharedWantListsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSharedWantListsQuery = { __typename?: 'Query', sharedWantLists: { __typename?: 'SharedWantListConnection', nodes?: Array<{ __typename?: 'SharedWantList', id: number, publiclyShared: boolean, user: { __typename?: 'User', id: number, handle?: string | null } } | null> | null } };

export type GetUserInventoryForCardSetQueryVariables = Exact<{
  cardSetCode: CardSetCodeEnum;
  userHandle?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserInventoryForCardSetQuery = { __typename?: 'Query', userCardSetInventory: { __typename?: 'UserCardInventoryConnection', nodes?: Array<{ __typename?: 'UserCardInventory', id: string, cardDetails: { __typename?: 'CardDetails', name: string, cardNumber: string, rarity: string, cardType: string }, standardInventory?: { __typename?: 'UserVariantInventory', variantId: number, inventory: number, wantedCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, foilInventory?: { __typename?: 'UserVariantInventory', variantId: number, inventory: number, wantedCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, hyperspaceInventory?: { __typename?: 'UserVariantInventory', variantId: number, inventory: number, wantedCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, hyperspaceFoilInventory?: { __typename?: 'UserVariantInventory', variantId: number, inventory: number, wantedCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null, showcaseInventory?: { __typename?: 'UserVariantInventory', variantId: number, inventory: number, wantedCount: number, missing: { __typename?: 'MissingCount', count: number, isDefault: boolean } } | null } | null> | null } };

export type GetUserOffersQueryVariables = Exact<{
  type: OfferTypeEnum;
}>;


export type GetUserOffersQuery = { __typename?: 'Query', userOffers: { __typename?: 'OfferConnection', nodes?: Array<{ __typename?: 'Offer', id: string, status: OfferStatusEnum, totalPrice: number, createdAt: string, updatedAt: string, buyer: { __typename?: 'User', id: number, handle?: string | null }, seller: { __typename?: 'User', id: number, handle?: string | null }, offerItems: Array<{ __typename?: 'OfferItem', id: string, quantity: number, price: number, cardVariant: { __typename?: 'CardVariant', id: string, variantType: CardVariantTypeEnum, card: { __typename?: 'Card', id: string, name: string } } }> } | null> | null } };

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: number, name: string, handle?: string | null, email: string } | null };

export type GetUserSharingSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSharingSettingsQuery = { __typename?: 'Query', userSharingSettings: { __typename?: 'UserSharingSettings', collectionSharedWithAll: boolean, wantListSharedWithAll: boolean } };

export type GetUserWantListQueryVariables = Exact<{
  userHandle?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserWantListQuery = { __typename?: 'Query', userWantList: { __typename?: 'UserWantItemConnection', nodes?: Array<{ __typename?: 'UserWantItem', id: string, cardDetails: { __typename?: 'UserWantCardDetails', name: string, fullCardNumber: string, rarity: string, cardType: string, aspects?: Array<AspectTypeEnum> | null, cardSetName: string, cardSetId: string }, variantCounts: Array<{ __typename?: 'VariantCount', variantId: string, variantType: CardVariantTypeEnum, count: number, publishedCount: number }> } | null> | null } };


export const CreateOfferDocument = gql`
    mutation CreateOffer($input: CreateOfferInput!) {
  createOffer(input: $input) {
    offer {
      id
      seller {
        id
        name
      }
      buyer {
        id
        name
      }
      offerItems {
        id
        cardVariant {
          id
          variantType
        }
        quantity
        price
      }
      totalPrice
      status
      createdAt
    }
    errors {
      field
      message
    }
  }
}
    `;
export type CreateOfferMutationFn = Apollo.MutationFunction<CreateOfferMutation, CreateOfferMutationVariables>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfferMutation(baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CreateOfferDocument, options);
      }
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<CreateOfferMutation, CreateOfferMutationVariables>;
export const RescindOfferDocument = gql`
    mutation RescindOffer($offerId: ID!) {
  rescindOffer(input: {offerId: $offerId}) {
    offer {
      id
      status
    }
    errors {
      field
      message
    }
  }
}
    `;
export type RescindOfferMutationFn = Apollo.MutationFunction<RescindOfferMutation, RescindOfferMutationVariables>;

/**
 * __useRescindOfferMutation__
 *
 * To run a mutation, you first call `useRescindOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescindOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescindOfferMutation, { data, loading, error }] = useRescindOfferMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useRescindOfferMutation(baseOptions?: Apollo.MutationHookOptions<RescindOfferMutation, RescindOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescindOfferMutation, RescindOfferMutationVariables>(RescindOfferDocument, options);
      }
export type RescindOfferMutationHookResult = ReturnType<typeof useRescindOfferMutation>;
export type RescindOfferMutationResult = Apollo.MutationResult<RescindOfferMutation>;
export type RescindOfferMutationOptions = Apollo.BaseMutationOptions<RescindOfferMutation, RescindOfferMutationVariables>;
export const UpdateCollectionSharingDocument = gql`
    mutation UpdateCollectionSharing($input: UpdateCollectionSharingInput!) {
  updateCollectionSharing(input: $input) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateCollectionSharingMutationFn = Apollo.MutationFunction<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>;

/**
 * __useUpdateCollectionSharingMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionSharingMutation, { data, loading, error }] = useUpdateCollectionSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionSharingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>(UpdateCollectionSharingDocument, options);
      }
export type UpdateCollectionSharingMutationHookResult = ReturnType<typeof useUpdateCollectionSharingMutation>;
export type UpdateCollectionSharingMutationResult = Apollo.MutationResult<UpdateCollectionSharingMutation>;
export type UpdateCollectionSharingMutationOptions = Apollo.BaseMutationOptions<UpdateCollectionSharingMutation, UpdateCollectionSharingMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    user {
      id
      handle
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateUserVariantInventoryDocument = gql`
    mutation UpdateUserVariantInventory($variantId: Int!, $field: String!, $newValue: Int) {
  updateUserVariantInventory(
    input: {variantId: $variantId, field: $field, newValue: $newValue}
  ) {
    userVariantInventory {
      id
      inventory
      variantId
      missing {
        count
        isDefault
      }
      wantedCount
      publishedWantedCount
    }
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateUserVariantInventoryMutationFn = Apollo.MutationFunction<UpdateUserVariantInventoryMutation, UpdateUserVariantInventoryMutationVariables>;

/**
 * __useUpdateUserVariantInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateUserVariantInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserVariantInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserVariantInventoryMutation, { data, loading, error }] = useUpdateUserVariantInventoryMutation({
 *   variables: {
 *      variantId: // value for 'variantId'
 *      field: // value for 'field'
 *      newValue: // value for 'newValue'
 *   },
 * });
 */
export function useUpdateUserVariantInventoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserVariantInventoryMutation, UpdateUserVariantInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserVariantInventoryMutation, UpdateUserVariantInventoryMutationVariables>(UpdateUserVariantInventoryDocument, options);
      }
export type UpdateUserVariantInventoryMutationHookResult = ReturnType<typeof useUpdateUserVariantInventoryMutation>;
export type UpdateUserVariantInventoryMutationResult = Apollo.MutationResult<UpdateUserVariantInventoryMutation>;
export type UpdateUserVariantInventoryMutationOptions = Apollo.BaseMutationOptions<UpdateUserVariantInventoryMutation, UpdateUserVariantInventoryMutationVariables>;
export const UpdateWantListSharingDocument = gql`
    mutation UpdateWantListSharing($input: UpdateWantListSharingInput!) {
  updateWantListSharing(input: $input) {
    success
    errors {
      field
      message
    }
  }
}
    `;
export type UpdateWantListSharingMutationFn = Apollo.MutationFunction<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>;

/**
 * __useUpdateWantListSharingMutation__
 *
 * To run a mutation, you first call `useUpdateWantListSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWantListSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWantListSharingMutation, { data, loading, error }] = useUpdateWantListSharingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWantListSharingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>(UpdateWantListSharingDocument, options);
      }
export type UpdateWantListSharingMutationHookResult = ReturnType<typeof useUpdateWantListSharingMutation>;
export type UpdateWantListSharingMutationResult = Apollo.MutationResult<UpdateWantListSharingMutation>;
export type UpdateWantListSharingMutationOptions = Apollo.BaseMutationOptions<UpdateWantListSharingMutation, UpdateWantListSharingMutationVariables>;
export const GetCardDocument = gql`
    query GetCard($id: ID!) {
  card(id: $id) {
    id
    name
    subtitle
    cardType
    rarity
    cost
    power
    hp
    arena
    aspects
    traits
    frontText
    backText
    unique
    keywords
    image {
      frontUrl
      backUrl
      id
    }
    cardVariants {
      id
      variantType
      image {
        id
        frontUrl
        backUrl
      }
    }
  }
}
    `;

/**
 * __useGetCardQuery__
 *
 * To run a query within a React component, call `useGetCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardQuery(baseOptions: Apollo.QueryHookOptions<GetCardQuery, GetCardQueryVariables> & ({ variables: GetCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
      }
export function useGetCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export function useGetCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export type GetCardQueryHookResult = ReturnType<typeof useGetCardQuery>;
export type GetCardLazyQueryHookResult = ReturnType<typeof useGetCardLazyQuery>;
export type GetCardSuspenseQueryHookResult = ReturnType<typeof useGetCardSuspenseQuery>;
export type GetCardQueryResult = Apollo.QueryResult<GetCardQuery, GetCardQueryVariables>;
export const GetCardSetDocument = gql`
    query GetCardSet($cardSetCode: CardSetCodeEnum!) {
  cardSet(cardSetCode: $cardSetCode) {
    id
    name
    code
    cards {
      nodes {
        id
        name
        subtitle
        standardCardNumber
        hyperspaceCardNumber
        cost
        rarity
        power
        hp
        cardType
        arena
        aspects
        traits
        keywords
        image {
          frontUrl
          backUrl
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetCardSetQuery__
 *
 * To run a query within a React component, call `useGetCardSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardSetQuery({
 *   variables: {
 *      cardSetCode: // value for 'cardSetCode'
 *   },
 * });
 */
export function useGetCardSetQuery(baseOptions: Apollo.QueryHookOptions<GetCardSetQuery, GetCardSetQueryVariables> & ({ variables: GetCardSetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardSetQuery, GetCardSetQueryVariables>(GetCardSetDocument, options);
      }
export function useGetCardSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardSetQuery, GetCardSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardSetQuery, GetCardSetQueryVariables>(GetCardSetDocument, options);
        }
export function useGetCardSetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardSetQuery, GetCardSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardSetQuery, GetCardSetQueryVariables>(GetCardSetDocument, options);
        }
export type GetCardSetQueryHookResult = ReturnType<typeof useGetCardSetQuery>;
export type GetCardSetLazyQueryHookResult = ReturnType<typeof useGetCardSetLazyQuery>;
export type GetCardSetSuspenseQueryHookResult = ReturnType<typeof useGetCardSetSuspenseQuery>;
export type GetCardSetQueryResult = Apollo.QueryResult<GetCardSetQuery, GetCardSetQueryVariables>;
export const GetCardSetsDocument = gql`
    query GetCardSets {
  cardSets(first: 250) {
    nodes {
      id
      code
      name
      description
      imageUrl
    }
  }
}
    `;

/**
 * __useGetCardSetsQuery__
 *
 * To run a query within a React component, call `useGetCardSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardSetsQuery(baseOptions?: Apollo.QueryHookOptions<GetCardSetsQuery, GetCardSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardSetsQuery, GetCardSetsQueryVariables>(GetCardSetsDocument, options);
      }
export function useGetCardSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardSetsQuery, GetCardSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardSetsQuery, GetCardSetsQueryVariables>(GetCardSetsDocument, options);
        }
export function useGetCardSetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardSetsQuery, GetCardSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardSetsQuery, GetCardSetsQueryVariables>(GetCardSetsDocument, options);
        }
export type GetCardSetsQueryHookResult = ReturnType<typeof useGetCardSetsQuery>;
export type GetCardSetsLazyQueryHookResult = ReturnType<typeof useGetCardSetsLazyQuery>;
export type GetCardSetsSuspenseQueryHookResult = ReturnType<typeof useGetCardSetsSuspenseQuery>;
export type GetCardSetsQueryResult = Apollo.QueryResult<GetCardSetsQuery, GetCardSetsQueryVariables>;
export const GetCardsDocument = gql`
    query GetCards {
  cards(first: 10) {
    nodes {
      id
      name
      subtitle
      cardType
      rarity
      cost
      power
      hp
      arena
      aspects
      traits
      frontText
      unique
    }
  }
}
    `;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
      }
export function useGetCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
        }
export function useGetCardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsSuspenseQueryHookResult = ReturnType<typeof useGetCardsSuspenseQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const GetSharedWantListsDocument = gql`
    query GetSharedWantLists {
  sharedWantLists {
    nodes {
      id
      user {
        id
        handle
      }
      publiclyShared
    }
  }
}
    `;

/**
 * __useGetSharedWantListsQuery__
 *
 * To run a query within a React component, call `useGetSharedWantListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedWantListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedWantListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSharedWantListsQuery(baseOptions?: Apollo.QueryHookOptions<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>(GetSharedWantListsDocument, options);
      }
export function useGetSharedWantListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>(GetSharedWantListsDocument, options);
        }
export function useGetSharedWantListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>(GetSharedWantListsDocument, options);
        }
export type GetSharedWantListsQueryHookResult = ReturnType<typeof useGetSharedWantListsQuery>;
export type GetSharedWantListsLazyQueryHookResult = ReturnType<typeof useGetSharedWantListsLazyQuery>;
export type GetSharedWantListsSuspenseQueryHookResult = ReturnType<typeof useGetSharedWantListsSuspenseQuery>;
export type GetSharedWantListsQueryResult = Apollo.QueryResult<GetSharedWantListsQuery, GetSharedWantListsQueryVariables>;
export const GetUserInventoryForCardSetDocument = gql`
    query GetUserInventoryForCardSet($cardSetCode: CardSetCodeEnum!, $userHandle: String) {
  userCardSetInventory(cardSetCode: $cardSetCode, userHandle: $userHandle) {
    nodes {
      id
      cardDetails {
        name
        cardNumber
        rarity
        cardType
      }
      standardInventory {
        variantId
        inventory
        missing {
          count
          isDefault
        }
        wantedCount
      }
      foilInventory {
        variantId
        inventory
        missing {
          count
          isDefault
        }
        wantedCount
      }
      hyperspaceInventory {
        variantId
        inventory
        missing {
          count
          isDefault
        }
        wantedCount
      }
      hyperspaceFoilInventory {
        variantId
        inventory
        missing {
          count
          isDefault
        }
        wantedCount
      }
      showcaseInventory {
        variantId
        inventory
        missing {
          count
          isDefault
        }
        wantedCount
      }
    }
  }
}
    `;

/**
 * __useGetUserInventoryForCardSetQuery__
 *
 * To run a query within a React component, call `useGetUserInventoryForCardSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInventoryForCardSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInventoryForCardSetQuery({
 *   variables: {
 *      cardSetCode: // value for 'cardSetCode'
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useGetUserInventoryForCardSetQuery(baseOptions: Apollo.QueryHookOptions<GetUserInventoryForCardSetQuery, GetUserInventoryForCardSetQueryVariables> & ({ variables: GetUserInventoryForCardSetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInventoryForCardSetQuery, GetUserInventoryForCardSetQueryVariables>(GetUserInventoryForCardSetDocument, options);
      }
export function useGetUserInventoryForCardSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInventoryForCardSetQuery, GetUserInventoryForCardSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInventoryForCardSetQuery, GetUserInventoryForCardSetQueryVariables>(GetUserInventoryForCardSetDocument, options);
        }
export function useGetUserInventoryForCardSetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserInventoryForCardSetQuery, GetUserInventoryForCardSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserInventoryForCardSetQuery, GetUserInventoryForCardSetQueryVariables>(GetUserInventoryForCardSetDocument, options);
        }
export type GetUserInventoryForCardSetQueryHookResult = ReturnType<typeof useGetUserInventoryForCardSetQuery>;
export type GetUserInventoryForCardSetLazyQueryHookResult = ReturnType<typeof useGetUserInventoryForCardSetLazyQuery>;
export type GetUserInventoryForCardSetSuspenseQueryHookResult = ReturnType<typeof useGetUserInventoryForCardSetSuspenseQuery>;
export type GetUserInventoryForCardSetQueryResult = Apollo.QueryResult<GetUserInventoryForCardSetQuery, GetUserInventoryForCardSetQueryVariables>;
export const GetUserOffersDocument = gql`
    query GetUserOffers($type: OfferTypeEnum!) {
  userOffers(type: $type) {
    nodes {
      id
      status
      totalPrice
      createdAt
      updatedAt
      buyer {
        id
        handle
      }
      seller {
        id
        handle
      }
      offerItems {
        id
        quantity
        price
        cardVariant {
          id
          variantType
          card {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserOffersQuery__
 *
 * To run a query within a React component, call `useGetUserOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOffersQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetUserOffersQuery(baseOptions: Apollo.QueryHookOptions<GetUserOffersQuery, GetUserOffersQueryVariables> & ({ variables: GetUserOffersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOffersQuery, GetUserOffersQueryVariables>(GetUserOffersDocument, options);
      }
export function useGetUserOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOffersQuery, GetUserOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOffersQuery, GetUserOffersQueryVariables>(GetUserOffersDocument, options);
        }
export function useGetUserOffersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserOffersQuery, GetUserOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserOffersQuery, GetUserOffersQueryVariables>(GetUserOffersDocument, options);
        }
export type GetUserOffersQueryHookResult = ReturnType<typeof useGetUserOffersQuery>;
export type GetUserOffersLazyQueryHookResult = ReturnType<typeof useGetUserOffersLazyQuery>;
export type GetUserOffersSuspenseQueryHookResult = ReturnType<typeof useGetUserOffersSuspenseQuery>;
export type GetUserOffersQueryResult = Apollo.QueryResult<GetUserOffersQuery, GetUserOffersQueryVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile {
  user {
    id
    name
    handle
    email
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export function useGetUserProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileSuspenseQueryHookResult = ReturnType<typeof useGetUserProfileSuspenseQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetUserSharingSettingsDocument = gql`
    query GetUserSharingSettings {
  userSharingSettings {
    collectionSharedWithAll
    wantListSharedWithAll
  }
}
    `;

/**
 * __useGetUserSharingSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSharingSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSharingSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSharingSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserSharingSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserSharingSettingsQuery, GetUserSharingSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSharingSettingsQuery, GetUserSharingSettingsQueryVariables>(GetUserSharingSettingsDocument, options);
      }
export function useGetUserSharingSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSharingSettingsQuery, GetUserSharingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSharingSettingsQuery, GetUserSharingSettingsQueryVariables>(GetUserSharingSettingsDocument, options);
        }
export function useGetUserSharingSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserSharingSettingsQuery, GetUserSharingSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserSharingSettingsQuery, GetUserSharingSettingsQueryVariables>(GetUserSharingSettingsDocument, options);
        }
export type GetUserSharingSettingsQueryHookResult = ReturnType<typeof useGetUserSharingSettingsQuery>;
export type GetUserSharingSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSharingSettingsLazyQuery>;
export type GetUserSharingSettingsSuspenseQueryHookResult = ReturnType<typeof useGetUserSharingSettingsSuspenseQuery>;
export type GetUserSharingSettingsQueryResult = Apollo.QueryResult<GetUserSharingSettingsQuery, GetUserSharingSettingsQueryVariables>;
export const GetUserWantListDocument = gql`
    query GetUserWantList($userHandle: String) {
  userWantList(userHandle: $userHandle) {
    nodes {
      id
      cardDetails {
        name
        fullCardNumber
        rarity
        cardType
        aspects
        cardSetName
        cardSetId
      }
      variantCounts {
        variantId
        variantType
        count
        publishedCount
      }
    }
  }
}
    `;

/**
 * __useGetUserWantListQuery__
 *
 * To run a query within a React component, call `useGetUserWantListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWantListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWantListQuery({
 *   variables: {
 *      userHandle: // value for 'userHandle'
 *   },
 * });
 */
export function useGetUserWantListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserWantListQuery, GetUserWantListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserWantListQuery, GetUserWantListQueryVariables>(GetUserWantListDocument, options);
      }
export function useGetUserWantListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserWantListQuery, GetUserWantListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserWantListQuery, GetUserWantListQueryVariables>(GetUserWantListDocument, options);
        }
export function useGetUserWantListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserWantListQuery, GetUserWantListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserWantListQuery, GetUserWantListQueryVariables>(GetUserWantListDocument, options);
        }
export type GetUserWantListQueryHookResult = ReturnType<typeof useGetUserWantListQuery>;
export type GetUserWantListLazyQueryHookResult = ReturnType<typeof useGetUserWantListLazyQuery>;
export type GetUserWantListSuspenseQueryHookResult = ReturnType<typeof useGetUserWantListSuspenseQuery>;
export type GetUserWantListQueryResult = Apollo.QueryResult<GetUserWantListQuery, GetUserWantListQueryVariables>;