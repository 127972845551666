import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faGlobe } from '@fortawesome/free-solid-svg-icons';
import CardHover from '../../card/CardHover';
import { UserWantItem, CardTypeEnum } from '../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';

interface WantCardProps {
    wantedCard: UserWantItem;
    isOwnWantList: boolean;
}

const WantCard: React.FC<WantCardProps> = ({ wantedCard, isOwnWantList }) => {
    const getAspectClasses = (aspects: string[] | null | undefined): string => {
        if (!aspects || aspects.length === 0) return 'aspect-none';
        const priorityAspects = aspects.filter(aspect => !['villainy', 'heroism'].includes(aspect.toLowerCase()));
        if (priorityAspects.length > 0) {
            return `aspect-${priorityAspects[0].toLowerCase()}`;
        }
        return `aspect-${aspects[0].toLowerCase()}`;
    };

    const isLandscape = wantedCard.cardDetails.cardType === CardTypeEnum.Leader || wantedCard.cardDetails.cardType === CardTypeEnum.Base;

    return (
        <CardHover cardId={wantedCard.id} showImageOnly={true}>
            <div className={`want-card ${getAspectClasses(wantedCard.cardDetails.aspects)} ${isLandscape ? 'landscape' : ''}`}>
                <div className="want-card-header">
                    <span className="want-card-number">{wantedCard.cardDetails.fullCardNumber}</span>
                    <h3 className="want-card-name">{wantedCard.cardDetails.name}</h3>
                </div>
                <div className="want-card-variants">
                    {wantedCard.variantCounts.map((variant) => (
                        <div key={variant.variantId} className="want-card-variant">
                            <span className="variant-type">{getCardVariantTypeDisplay(variant.variantType)}</span>
                            <div className="want-counts">
                                <div className="want" title="Wanted">
                                    <FontAwesomeIcon icon={faHeart} className="icon-heart" />
                                    <span>{variant.count}</span>
                                </div>
                                {isOwnWantList && (
                                    <div className="published-want" title="Published">
                                        <FontAwesomeIcon icon={faGlobe} className="icon-globe" />
                                        <span>{variant.publishedCount}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </CardHover>
    );
};

export default WantCard;
