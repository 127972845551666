import React, { useState } from 'react';
import { Form, Button, Card, InputGroup } from 'react-bootstrap';
import { UserWantItem, OfferItemInput, CreateOfferInput } from '../../graphql/generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faDollarSign } from '@fortawesome/free-solid-svg-icons';

interface OfferFormProps {
    buyerHandle: string;
    wantListItems: UserWantItem[];
    onSubmit: (offer: CreateOfferInput) => void;
    isSubmitting: boolean;
}

const OfferForm: React.FC<OfferFormProps> = ({ buyerHandle, wantListItems, onSubmit, isSubmitting }) => {
    const [selectedItems, setSelectedItems] = useState<OfferItemInput[]>([]);

    const handleQuantityChange = (variantId: string, change: number) => {
        setSelectedItems(prevItems => {
            const existingItem = prevItems.find(item => item.variantId === variantId);
            if (existingItem) {
                const newQuantity = Math.max(0, (existingItem.quantity || 0) + change);
                if (newQuantity === 0) {
                    return prevItems.filter(item => item.variantId !== variantId);
                }
                return prevItems.map(item =>
                    item.variantId === variantId ? { ...item, quantity: newQuantity } : item
                );
            } else if (change > 0) {
                return [...prevItems, { variantId, quantity: 1, price: 0 }];
            }
            return prevItems;
        });
    };

    const handlePriceChange = (variantId: string, price: number) => {
        setSelectedItems(prevItems =>
            prevItems.map(item =>
                item.variantId === variantId ? { ...item, price } : item
            )
        );
    };

    const calculateTotalPrice = (): number => {
        return selectedItems.reduce((sum, item) => sum + (item.price || 0) * (item.quantity || 0), 0);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const offerData: CreateOfferInput = {
            buyerHandle,
            offerItems: selectedItems.filter(item => (item.quantity || 0) > 0),
        };
        onSubmit(offerData);
    };

    return (
        <Form onSubmit={handleSubmit} className="offer-form">
            {wantListItems.map((wantedCard) => (
                <Card key={wantedCard.id} className="mb-4 bg-space-dark text-space-light border-space-blue">
                    <Card.Header className="bg-space-dark-blue">
                        <h4 className="text-space-gold mb-0">{wantedCard.cardDetails.name}</h4>
                    </Card.Header>
                    <Card.Body>
                        {wantedCard.variantCounts.map((variant) => {
                            const selectedItem = selectedItems.find(item => item.variantId === variant.variantId);
                            const quantity = selectedItem?.quantity || 0;
                            return (
                                <div key={variant.variantId} className="mb-3 d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <p className="mb-1">{variant.variantType} (Wanted: {variant.count})</p>
                                        <div className="d-flex align-items-center">
                                            <Button
                                                variant="outline-space-blue"
                                                size="sm"
                                                onClick={() => handleQuantityChange(variant.variantId, -1)}
                                                disabled={quantity === 0}
                                            >
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <span className="mx-2">{quantity}</span>
                                            <Button
                                                variant="outline-space-blue"
                                                size="sm"
                                                onClick={() => handleQuantityChange(variant.variantId, 1)}
                                                disabled={quantity >= variant.count}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </div>
                                    </div>
                                    {quantity > 0 && (
                                        <InputGroup className="w-auto ml-3">
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faDollarSign} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                value={selectedItem?.price || ''}
                                                onChange={(e) => handlePriceChange(variant.variantId, parseFloat(e.target.value))}
                                                min={0}
                                                step={0.01}
                                                disabled={isSubmitting}
                                                className="bg-space-light text-space-white"
                                            />
                                        </InputGroup>
                                    )}
                                </div>
                            );
                        })}
                    </Card.Body>
                </Card>
            ))}
            <div className="font-weight-bold mb-4 text-space-gold">Total Price: ${calculateTotalPrice().toFixed(2)}</div>
            <Button
                type="submit"
                disabled={isSubmitting || selectedItems.length === 0}
                variant="outline-space-gold"
            >
                {isSubmitting ? 'Submitting...' : 'Submit Offer'}
            </Button>
        </Form>
    );
};

export default OfferForm;
