import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, CardTypeEnum, useGetCardQuery } from '../../graphql/generated/graphql';
import Skeleton from '../layout/Skeleton';
import CardImage from './CardImage';

interface Position {
    top: number;
    left: number;
}

interface CardHoverProps {
    cardId: string;
    children: React.ReactNode;
    showImageOnly?: boolean;
}

const CardHover: React.FC<CardHoverProps> = ({ cardId, children, showImageOnly = false }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [position, setPosition] = useState<Position>({ top: 0, left: 0 });
    const containerRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const { data, loading, error } = useGetCardQuery({
        variables: { id: cardId },
        skip: !showTooltip,
    });

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const calculatePosition = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const tooltipWidth = 300;
        const tooltipHeight = showImageOnly ? 418 : 400;
        let left = e.clientX;
        let top = e.clientY + 20;

        if (left + tooltipWidth > window.innerWidth) {
            left = window.innerWidth - tooltipWidth - 10;
        }
        if (top + tooltipHeight > window.innerHeight) {
            top = e.clientY - tooltipHeight - 10;
        }

        return { top, left };
    }, [showImageOnly]);

    const handleMouseEnter = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (window.innerWidth > 768) {
            setPosition(calculatePosition(e));
            timeoutRef.current = setTimeout(() => setShowTooltip(true), 300);
        }
    }, [calculatePosition]);

    const handleMouseLeave = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setShowTooltip(false);
    }, []);

    const renderCardContent = useCallback(() => {
        if (loading) return <LoadingSkeleton showImageOnly={showImageOnly} />;
        if (error) return <div>Error loading card details</div>;
        if (!data?.card) return <div>No card data available</div>;

        return showImageOnly
            ? <CardImageContent card={data.card} />
            : <CardDetailsContent card={data.card} />;
    }, [loading, error, data, showImageOnly]);

    return (
        <div
            ref={containerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {showTooltip && (
                <div
                    className={`card-hover-tooltip ${showTooltip ? 'visible' : ''} ${showImageOnly ? 'image-only' : ''}`}
                    style={{ top: `${position.top}px`, left: `${position.left}px` }}
                >
                    {renderCardContent()}
                </div>
            )}
        </div>
    );
};

const LoadingSkeleton: React.FC<{ showImageOnly: boolean }> = ({ showImageOnly }) => {
    if (!showImageOnly) return null;
    return (
        <div className="card-hover-content">
            <Skeleton height="24px" className="mb-2" />
            <Skeleton height="18px" width="70%" className="mb-3" />
            <div className="d-flex justify-content-between mb-3">
                <Skeleton width="30%" height="16px" />
                <Skeleton width="30%" height="16px" />
                <Skeleton width="30%" height="16px" />
            </div>
            <Skeleton height="16px" className="mb-2" />
            <Skeleton height="16px" className="mb-2" />
            <Skeleton height="16px" width="80%" />
        </div>
    );
};

const CardImageContent: React.FC<{ card: Card }> = ({ card }) => {
    const isLandscape = card.cardType === CardTypeEnum.Leader || card.cardType === CardTypeEnum.Base;

    return (
        <div className={`card-hover-image-container ${isLandscape ? 'landscape' : 'portrait'}`}>
            <CardImage
                image={card.cardVariants?.[0]?.image}
                showBack={false}
                name={card.name}
                isLandscape={isLandscape}
            />
        </div>
    );
};

const CardDetailsContent: React.FC<{ card: Card }> = ({ card }) => {
    const primaryAspect = card.aspects && card.aspects.length > 0 ? card.aspects[0].toLowerCase() : 'none';

    return (
        <div className={`card-hover-content aspect-${primaryAspect}`}>
            <h5 className="card-hover-title">{card.name}</h5>
            {card.subtitle && <h6 className="card-hover-subtitle">{card.subtitle}</h6>}
            <div className="card-hover-details">
                <span className="card-hover-badge card-hover-badge-type">{card.cardType}</span>
                <span className="card-hover-badge card-hover-badge-rarity">{card.rarity}</span>
                <span className="card-hover-badge card-hover-badge-cost">Cost: {card.cost}</span>
                {card.power && <span className="card-hover-badge card-hover-badge-power">Power: {card.power}</span>}
                {card.hp && <span className="card-hover-badge card-hover-badge-hp">HP: {card.hp}</span>}
                {card.arena && <span className="card-hover-badge card-hover-badge-arena">{card.arena}</span>}
            </div>
            {card.aspects && card.aspects.length > 0 && (
                <div className="card-hover-aspects">
                    {card.aspects.map((aspect: string, index: number) => (
                        <span key={index} className="card-hover-badge card-hover-badge-aspect">{aspect}</span>
                    ))}
                </div>
            )}
            {card.frontText && (
                <div className="card-hover-text">
                    <strong>Front Text:</strong>
                    <p>{card.frontText}</p>
                </div>
            )}
            {card.backText && (
                <div className="card-hover-text">
                    <strong>Back Text:</strong>
                    <p>{card.backText}</p>
                </div>
            )}
        </div>
    );
};

export default CardHover;
