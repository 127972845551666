import React from 'react';
import { Button } from 'react-bootstrap';
import { useGetUserOffersQuery, useRescindOfferMutation, OfferTypeEnum, OfferStatusEnum } from '../../graphql/generated/graphql';
import { Link } from 'react-router-dom';
import { getCardVariantTypeDisplay } from '../../utils/cardUtils';
interface OfferListProps {
    type: OfferTypeEnum;
}

const OfferList: React.FC<OfferListProps> = ({ type }) => {
    const { data, loading, error } = useGetUserOffersQuery({
        variables: { type },
    });

    const [rescindOffer] = useRescindOfferMutation();

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const handleRescind = async (offerId: string) => {
        try {
            await rescindOffer({ variables: { offerId } });
            // Optionally refetch the offers or update the cache here
        } catch (error) {
            console.error('Error rescinding offer:', error);
        }
    };

    const offers = data?.userOffers?.nodes || [];

    // Sort offers by status: PENDING first, then others
    const sortedOffers = [...offers].sort((a, b) => {
        if (a?.status === OfferStatusEnum.Pending) return -1;
        if (b?.status === OfferStatusEnum.Pending) return 1;
        return 0;
    });

    return (
        <div className="space-table-container">
            <table className="space-table">
                <thead className="space-table-header">
                    <tr>
                        <th>{type === OfferTypeEnum.Sent ? 'Buyer' : 'Seller'}</th>
                        <th>Cards</th>
                        <th>Status</th>
                        <th>Want List</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedOffers.length > 0 ? (
                        sortedOffers.map((offer) => (
                            <tr key={offer?.id}>
                                <td>{type === OfferTypeEnum.Sent ? offer?.buyer?.handle : offer?.seller?.handle}</td>
                                <td>
                                    {offer?.offerItems.map((item, index) => (
                                        <div
                                            key={`${item.cardVariant.id}`}
                                            className={`offer-item d-flex justify-content-between align-items-center ${index !== offer.offerItems.length - 1 ? 'mb-2' : ''}`}
                                        >
                                            <span className="card-info">
                                                {item.cardVariant.card.name} ({getCardVariantTypeDisplay(item.cardVariant.variantType)})
                                            </span>
                                            <span className="quantity-price-info">
                                                {item.quantity} x ${item.price.toFixed(2)}
                                            </span>
                                        </div>
                                    ))}
                                </td>
                                <td>{offer?.status}</td>
                                <td>
                                    <Link to={`/collections/${type === OfferTypeEnum.Sent ? offer?.buyer?.handle : offer?.seller?.handle}/want`} className="space-table-link">
                                        View Want List
                                    </Link>
                                </td>
                                <td>
                                    {type === OfferTypeEnum.Sent && offer?.status === OfferStatusEnum.Pending && (
                                        <Button variant="danger" size="sm" onClick={() => handleRescind(offer?.id ?? '')}>
                                            Rescind
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6}>No offers available.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default OfferList;
