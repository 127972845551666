import React, { useState, useEffect } from 'react';

interface MissingFieldProps {
    initialValue: { count: number | null; isDefault: boolean };
    onUpdate: (newValue: number | null) => void;
}

const MissingField: React.FC<MissingFieldProps> = ({ initialValue, onUpdate }) => {
    const [value, setValue] = useState(initialValue.count?.toString() || '');
    const [isDefault, setIsDefault] = useState(initialValue.isDefault);

    useEffect(() => {
        setValue(initialValue.count?.toString() || '');
        setIsDefault(initialValue.isDefault);
    }, [initialValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setIsDefault(false);
    };

    const handleBlur = () => {
        if (value === '') {
            onUpdate(null);
            setIsDefault(true);
        } else {
            const newValue = parseInt(value, 10);
            if (!isNaN(newValue) && newValue >= 0) {
                onUpdate(newValue);
                setIsDefault(false);
            } else {
                setValue(initialValue.count?.toString() || '');
                setIsDefault(initialValue.isDefault);
            }
        }
    };

    return (
        <input
            type="number"
            value={isDefault ? '' : value}
            onChange={handleChange}
            onBlur={handleBlur}
            min="0"
            className={`inventory-input ${isDefault ? 'default-value' : 'custom-value'}`}
            title={isDefault ? "Default missing count" : "Custom missing count"}
            placeholder={isDefault ? initialValue.count?.toString() : undefined}
        />
    );
};

export default MissingField;
