import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../constants/icons';
import { useAuth } from '../../contexts/AuthContext';
import NavLoginButton from '../auth/NavLoginButton';

const Header: React.FC = () => {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = async () => {
        await logout();
        navigate('/', { replace: true });
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">SWU Dashboard</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/card-sets"><FontAwesomeIcon icon={Icons.CardSets} className="me-1" /> Card Sets</Nav.Link>
                        {isAuthenticated ? (
                            <>
                                <Nav.Link as={Link} to="/collections/my"><FontAwesomeIcon icon={Icons.Collection} className="me-1" /> My Collection</Nav.Link>
                                <Nav.Link as={Link} to="/collections/my/want"><FontAwesomeIcon icon={Icons.Collection} className="me-1" /> My Want List</Nav.Link>
                                <Nav.Link as={Link} to="/trading"><FontAwesomeIcon icon={Icons.Trading} className="me-1" /> Trading</Nav.Link>
                                <Nav.Link as={Link} to="/profile"><FontAwesomeIcon icon={Icons.Profile} className="me-1" /> Profile</Nav.Link>
                                <Nav.Link as={Link} to="/settings"><FontAwesomeIcon icon={Icons.Settings} className="me-1" /> Settings</Nav.Link>
                            </>
                        ) : (
                            <Nav.Link as={Link} to="/trading"><FontAwesomeIcon icon={Icons.Trading} className="me-1" /> Trading</Nav.Link>
                        )}
                    </Nav>
                    <Nav>
                        {isAuthenticated ? (
                            <Button variant="outline-light" onClick={handleLogout}>Logout</Button>
                        ) : (
                            <NavLoginButton from={location.pathname} />
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
