import React, { useMemo, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Container, Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { useGetCardSetQuery, Card, CardSetCodeEnum } from '../../graphql/generated/graphql';
import CardTable from '../card/CardTable';
import CardFilters from '../filters/CardFilters';
import { useCardFilters } from '../../hooks/useCardFilters';
import ErrorDisplay from '../layout/ErrorDisplay';
import CardImageView from '../card/CardImageView';
import CardSetSkeleton from './CardSetSkeleton';

const CardSetView: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filtersOpen, setFiltersOpen] = React.useState(false);
    const { cardSetCode } = useParams<{ cardSetCode: string }>();

    const viewMode = searchParams.get('view') === 'list' ? 'list' : 'image';

    const setViewMode = (mode: 'list' | 'image') => {
        setSearchParams({ ...Object.fromEntries(searchParams), view: mode });
    };

    const parsedCardSetCode = useMemo(() => {
        if (!cardSetCode) return null;
        const upperCaseCode = cardSetCode.toUpperCase();
        if (Object.values(CardSetCodeEnum).includes(upperCaseCode as CardSetCodeEnum)) {
            return upperCaseCode as CardSetCodeEnum;
        }
        console.error(`Invalid card set code: ${cardSetCode}`);
        return null;
    }, [cardSetCode]);

    const { loading, error, data } = useGetCardSetQuery({
        variables: { cardSetCode: parsedCardSetCode! },
        skip: !parsedCardSetCode,
    });

    const cards = useMemo(() => (data?.cardSet?.cards?.nodes as Card[]) || [], [data]);
    const { filteredCards, filters, updateFilter, rarities, aspects, costs } = useCardFilters(cards);

    useEffect(() => {
        // Set default view mode to 'image' if not specified
        if (!searchParams.get('view')) {
            setSearchParams({ ...Object.fromEntries(searchParams), view: 'image' });
        }
    }, [searchParams, setSearchParams]);

    if (!parsedCardSetCode) {
        return <ErrorDisplay message="Invalid card set code" />;
    }

    if (error) return <ErrorDisplay message={error.message} />;

    return (
        <Container className="mt-4">
            <h1 className="mb-4 section-title text-space-gold">{data?.cardSet?.name || 'Loading card set...'}</h1>
            <div className="d-flex mb-3 justify-content-between align-items-center">
                <Button
                    onClick={() => setFiltersOpen(!filtersOpen)}
                    aria-controls="card-filters"
                    aria-expanded={filtersOpen}
                    className="filter-toggle-btn"
                    variant="outline-primary"
                >
                    {filtersOpen ? 'Hide Filters' : 'Show Filters'}
                </Button>
                <ButtonGroup>
                    <Button
                        variant={viewMode === 'image' ? 'primary' : 'outline-primary'}
                        onClick={() => setViewMode('image')}
                        aria-label="Image View"
                        title="Image View"
                    >
                        <FontAwesomeIcon icon={faThLarge} />
                    </Button>
                    <Button
                        variant={viewMode === 'list' ? 'primary' : 'outline-primary'}
                        onClick={() => setViewMode('list')}
                        aria-label="List View"
                        title="List View"
                    >
                        <FontAwesomeIcon icon={faList} />
                    </Button>
                </ButtonGroup>
            </div>
            <CardFilters
                filters={filters}
                updateFilter={updateFilter}
                rarities={rarities || []}
                aspects={aspects || []}
                costs={costs || []}
                disabled={loading}
                isOpen={filtersOpen}
                onClose={() => setFiltersOpen(false)}
            />
            <div className="card-table-container">
                {loading ? (
                    <CardSetSkeleton viewMode={viewMode} />
                ) : viewMode === 'list' ? (
                    <CardTable cards={filteredCards} />
                ) : (
                    <CardImageView cards={filteredCards} />
                )}
            </div>
        </Container>
    );
}

export default CardSetView;
