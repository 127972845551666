import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Image } from '../../graphql/generated/graphql';

interface CardImageProps {
    image: Image | null | undefined;
    name: string;
    isLandscape: boolean;
    showBack?: boolean;
}

const CardImage: React.FC<CardImageProps> = ({ image, name, isLandscape, showBack = false }) => {
    const [imageError, setImageError] = useState(false);
    const [width, setWidth] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth);
            }
        };

        const resizeObserver = new ResizeObserver(updateWidth);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => resizeObserver.disconnect();
    }, []);

    const imageUrl = useMemo(() => {
        if (!image) return null;
        const url = showBack ? image.backUrl : image.frontUrl;
        if (!url) return null;

        const hasExtension = /\.[^/.]+$/.test(url);
        return hasExtension ? url : `${url}/width=${width}`;
    }, [image, showBack, width]);

    const aspectRatio = isLandscape ? '4 / 3' : '5 / 7';
    const altText = `${name} ${showBack ? 'Back' : 'Front'}`;

    if (!imageUrl || imageError) {
        return (
            <div
                ref={containerRef}
                className="card-image-placeholder"
                style={{ aspectRatio }}
            >
                <span>{name} {showBack ? '(Back)' : ''}</span>
            </div>
        );
    }

    return (
        <div
            ref={containerRef}
            className={`card-image-container ${isLandscape ? 'landscape' : 'portrait'} mobile-friendly`}
            style={{ aspectRatio }}
        >
            <img
                src={imageUrl}
                alt={altText}
                onError={() => setImageError(true)}
                className="card-image"
                loading="lazy"
            />
        </div>
    );
};

export default CardImage;
