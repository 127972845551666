import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Spinner, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { useGetCardSetsQuery, useGetUserInventoryForCardSetQuery, CardSetCodeEnum, UserCardInventory, GetCardSetsQuery } from '../../graphql/generated/graphql';
import CardSetTabs from '../card_set/CardSetTabs';
import InventoryCardTable from './InventoryCardTable';
import NotFound from '../layout/NotFound';
import ErrorDisplay from '../layout/ErrorDisplay';

const useCardSetSelection = (cardSetsData: GetCardSetsQuery) => {
    const [selectedCardSetCode, setSelectedCardSetCode] = useState<CardSetCodeEnum>(CardSetCodeEnum.Sor);

    useEffect(() => {
        if (cardSetsData?.cardSets?.nodes?.length && !selectedCardSetCode) {
            setSelectedCardSetCode(cardSetsData.cardSets.nodes[0]?.code ?? CardSetCodeEnum.Sor);
        }
    }, [cardSetsData, selectedCardSetCode]);

    return [selectedCardSetCode, setSelectedCardSetCode] as const;
};

const CollectionView: React.FC = () => {
    const { handle: urlHandle } = useParams<{ handle: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, user } = useAuth();
    const [isEditMode, setIsEditMode] = useState(false);

    const isMyCollection = /^\/collections\/my(\/edit)?$/.test(location.pathname);
    const isOwnCollection = isMyCollection || (isAuthenticated && user?.handle === urlHandle);
    const effectiveHandle = isMyCollection ? user?.handle : urlHandle;

    useEffect(() => {
        if (isMyCollection && !isAuthenticated) {
            navigate('/login', { state: { from: location.pathname } });
        }
        setIsEditMode(location.pathname.endsWith('/edit'));
    }, [isMyCollection, isAuthenticated, navigate, location.pathname]);

    const { data: cardSetsData, loading: cardSetsLoading, error: cardSetsError } = useGetCardSetsQuery();
    const [selectedCardSetCode, setSelectedCardSetCode] = useCardSetSelection(cardSetsData ?? { cardSets: { nodes: [] } });

    const { loading: inventoryLoading, error: inventoryError, data: inventoryData } = useGetUserInventoryForCardSetQuery({
        variables: {
            cardSetCode: selectedCardSetCode!,
            userHandle: effectiveHandle
        },
        skip: !selectedCardSetCode || (effectiveHandle === undefined),
    });

    const toggleEditMode = () => {
        const newMode = !isEditMode;
        setIsEditMode(newMode);
        navigate(newMode ? `/collections/my/edit` : `/collections/my`);
    };

    const cardSets = useMemo(() =>
        cardSetsData?.cardSets?.nodes
            ?.filter((set): set is NonNullable<typeof set> => set !== null && set.code !== null && set.name !== null)
            .map(set => ({ code: set.code as CardSetCodeEnum, name: set.name })) || [],
        [cardSetsData]
    );

    if (cardSetsError || inventoryError) {
        return <ErrorDisplay message={cardSetsError?.message || inventoryError?.message || 'An error occurred'} />;
    }

    if (cardSets.length === 0 && !cardSetsLoading) {
        return <NotFound message="No card sets available" />;
    }

    if (!inventoryData?.userCardSetInventory && !inventoryLoading && !cardSetsLoading) {
        return <NotFound message="Collection not found" />;
    }

    const isLoading = cardSetsLoading || inventoryLoading;
    const collectionOwner = isOwnCollection ? 'My' : `${effectiveHandle}'s`;

    const exportCollection = async (format: 'json' | 'csv') => {
        if (!effectiveHandle) return;

        try {
            const response = await fetch(`/api/export_collection?user_handle=${effectiveHandle}&format=${format}`);
            const blob = await response.blob();
            const filename = getFilenameFromResponse(response, format);
            downloadBlob(blob, filename);
        } catch (error) {
            console.error('Error exporting collection:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const getFilenameFromResponse = (response: Response, format: string): string => {
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="?(.+?)"?(;|$)/i);
            if (filenameMatch) return filenameMatch[1];
        }
        return `${effectiveHandle}_collection_export.${format}`;
    };

    const downloadBlob = (blob: Blob, filename: string) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <Container className="mt-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h1>{collectionOwner} Collection</h1>
                <div className="d-flex align-items-center">
                    {isOwnCollection && (
                        <Button
                            onClick={toggleEditMode}
                            disabled={isLoading}
                            variant="outline-space-blue"
                            className="me-2 btn-icon"
                            title={isEditMode ? "View Mode" : "Edit Mode"}
                        >
                            <FontAwesomeIcon icon={isEditMode ? faEye : faPencilAlt} />
                        </Button>
                    )}
                    {!isEditMode && (
                        <Dropdown>
                            <Dropdown.Toggle
                                as={Button}
                                variant="outline-space-blue"
                                id="dropdown-export"
                                disabled={isLoading}
                                title="Export Collection"
                                className="btn-icon"
                            >
                                <FontAwesomeIcon icon={faFileExport} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => exportCollection('json')}>Export as JSON</Dropdown.Item>
                                <Dropdown.Item onClick={() => exportCollection('csv')}>Export as CSV</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </div>
            <div className="card-set-tabs-container mb-4">
                <CardSetTabs
                    cardSets={cardSets}
                    selectedCardSet={selectedCardSetCode!}
                    onSelectCardSet={setSelectedCardSetCode}
                    disabled={isLoading}
                />
            </div>
            <div style={{ position: 'relative', minHeight: '400px' }}>
                {isLoading ? (
                    <div className="text-center p-5">
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : inventoryData?.userCardSetInventory?.nodes ? (
                    <InventoryCardTable
                        cards={inventoryData.userCardSetInventory.nodes as UserCardInventory[]}
                        isEditMode={isEditMode}
                        isOwner={isOwnCollection}
                    />
                ) : null}
            </div>
        </Container>
    );
};

export default CollectionView;
