import React, { useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { AspectTypeEnum } from '../../graphql/generated/graphql';
import AspectFilter from './AspectFilter';
import RarityFilter from './RarityFilter';
import TextFilter from './TextFilter';
import CostFilter from './CostFilter';
import { FilterOptions, FilterValue } from '../../types/cardFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface CardFiltersProps {
    filters: FilterOptions;
    updateFilter: (key: string, value: FilterValue) => void;
    rarities: string[];
    aspects: AspectTypeEnum[];
    costs: number[];
    disabled?: boolean;
    isOpen: boolean;
    onClose: () => void;
}

const CardFilters: React.FC<CardFiltersProps> = ({ filters, updateFilter, rarities, aspects, costs, disabled, isOpen, onClose }) => {
    const filterRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (filterRef.current && !filterRef.current.contains(event.target as Node) && isOpen) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div ref={filterRef} className={`card-filters ${isOpen ? 'open' : ''}`}>
            <Form className="card-filters__form">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 className="card-filters__title mb-0">Filter Cards</h3>
                    <Button
                        variant="link"
                        className="card-filters__close-btn d-md-none"
                        onClick={onClose}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
                <TextFilter
                    value={filters.searchText || ''}
                    onChange={(value) => updateFilter('searchText', value)}
                    disabled={disabled}
                />
                <RarityFilter
                    rarities={rarities || []}
                    selected={filters.selectedRarities || []}
                    onChange={(selected) => updateFilter('selectedRarities', selected)}
                    disabled={disabled}
                />
                <AspectFilter
                    aspects={aspects || []}
                    selected={filters.selectedAspects || []}
                    matchAll={filters.matchAllAspects || false}
                    onChange={(selected, matchAll) => {
                        updateFilter('selectedAspects', selected);
                        updateFilter('matchAllAspects', matchAll);
                    }}
                    disabled={disabled}
                />
                <CostFilter
                    costs={costs || []}
                    selectedCosts={filters.selectedCosts || []}
                    onChange={(selectedCosts) => updateFilter('selectedCosts', selectedCosts)}
                    disabled={disabled}
                />
            </Form>
        </div>
    );
}

export default CardFilters;
