import React, { useState } from 'react';

interface WantedFieldProps {
    initialValue: number;
    onUpdate: (newValue: number) => void;
}

const WantedField: React.FC<WantedFieldProps> = ({ initialValue, onUpdate }) => {
    const [value, setValue] = useState(initialValue.toString());

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleBlur = () => {
        const newValue = parseInt(value, 10);
        if (!isNaN(newValue) && newValue >= 0) {
            onUpdate(newValue);
        } else {
            setValue(initialValue.toString());
        }
    };

    return (
        <input
            type="number"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            min="0"
            className="inventory-input"
            title="Wanted"
        />
    );
};

export default WantedField;
