import React from 'react';
import { Container, Card } from 'react-bootstrap';
import SharingSettings from './SharingSettings';

const UserSettings: React.FC = () => {
    return (
        <Container className="mt-4">
            <h1 className="mb-4">User Settings</h1>
            <Card>
                <Card.Body>
                    <Card.Title>Sharing Settings</Card.Title>
                    <SharingSettings />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default UserSettings;
