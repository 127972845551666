import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UpdateUserVariantInventoryDocument, UpdateUserVariantInventoryMutation, useGetCardQuery, UserCardInventory } from '../../graphql/generated/graphql';
import { useSortedCardInventory, SortColumn, SortDirection } from '../../hooks/useSortedCardInventory';
import { InventoryCardTableHeader } from './InventoryCardTableHeader';
import { InventoryCardTableRow } from './InventoryCardTableRow';
import { CardVariantTypeEnum } from '../../graphql/generated/graphql';
import { getCardVariantTypeDisplay, orderedVariantTypes } from '../../utils/cardUtils';
import CardModal from '../card/CardModal';

export interface InventoryCardTableProps {
    cards: UserCardInventory[];
    isEditMode: boolean;
    isOwner: boolean;
}

export interface VariantColumn {
    type: CardVariantTypeEnum;
    label: string;
    countKey: string;
}

function getInventoryCountKey(variantType: CardVariantTypeEnum): string {
    switch (variantType) {
        case CardVariantTypeEnum.Standard:
            return 'standardInventory';
        case CardVariantTypeEnum.Foil:
            return 'foilInventory';
        case CardVariantTypeEnum.Hyperspace:
            return 'hyperspaceInventory';
        case CardVariantTypeEnum.HyperspaceFoil:
            return 'hyperspaceFoilInventory';
        default:
            return 'unknownInventory';
    }
}

export const variantColumns: VariantColumn[] = orderedVariantTypes.map(type => ({
    type,
    label: getCardVariantTypeDisplay(type),
    countKey: getInventoryCountKey(type),
}));

const InventoryCardTable: React.FC<InventoryCardTableProps> = ({ cards, isEditMode, isOwner }) => {
    const { sortedCards, sortColumn, sortDirection, handleSort } = useSortedCardInventory(cards);
    const [updateUserVariantInventory] = useMutation(UpdateUserVariantInventoryDocument);
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);

    const handleCardClick = (card: UserCardInventory) => {
        setSelectedCardId(card.id);
    };

    const handleCloseModal = () => {
        setSelectedCardId(null);
    };

    const { data: cardData, loading: cardLoading } = useGetCardQuery({
        variables: { id: selectedCardId || '' },
        skip: !selectedCardId,
    });

    const handleInventoryUpdate = async (variantId: number, field: 'inventory' | 'missing' | 'wanted', newValue: number | null, oldValue: number | null): Promise<UpdateUserVariantInventoryMutation> => {
        try {
            console.log(`Updating ${field} for variant ${variantId} from ${oldValue} to ${newValue}`);
            const result = await updateUserVariantInventory({
                variables: {
                    variantId,
                    field,
                    newValue,
                },
            });

            if (result.errors) {
                console.error('Error updating inventory:', result.errors);
                // Handle error (e.g., show an error message to the user)
            }
            return result.data as UpdateUserVariantInventoryMutation;
        } catch (error) {
            console.error('Error updating inventory:', error);
            // Handle error (e.g., show an error message to the user)
            throw error;
        }
    };

    return (
        <div className="space-table-container">
            <table className="space-table">
                <InventoryCardTableHeader
                    sortColumn={sortColumn as SortColumn}
                    sortDirection={sortDirection as SortDirection}
                    onSort={handleSort}
                />
                <tbody>
                    {sortedCards.map((cardInventory) => (
                        <InventoryCardTableRow
                            key={cardInventory.id}
                            cardInventory={cardInventory}
                            isEditMode={isEditMode}
                            isOwner={isOwner}
                            onInventoryUpdate={handleInventoryUpdate}
                            variantColumns={variantColumns}
                            onCardSelect={() => handleCardClick(cardInventory)}
                            className="card-row-clickable"
                        />
                    ))}
                </tbody>
            </table>
            {selectedCardId && (
                <CardModal
                    card={cardData?.card}
                    loading={cardLoading}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default InventoryCardTable;
