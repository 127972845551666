import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Tab, Spinner } from 'react-bootstrap';
import { Card as CardType, CardTypeEnum, CardVariant, CardVariantTypeEnum } from '../../graphql/generated/graphql';
import CardImage from './CardImage';
import { uniqBy, capitalize } from 'lodash';

interface CardModalProps {
    card: CardType | null | undefined;
    loading: boolean;
    onClose: () => void;
}

const filterVariants = (variants: CardVariant[]): CardVariant[] => {
    const uniqueVariants = uniqBy(variants, 'image.frontUrl');
    return uniqueVariants.map(variant => {
        const allVariantsForImage = variants.filter(v => v.image.frontUrl === variant.image.frontUrl);
        const nonFoilVariant = allVariantsForImage.find(v =>
            v.variantType === CardVariantTypeEnum.Standard ||
            v.variantType === CardVariantTypeEnum.Hyperspace
        );
        return nonFoilVariant || variant;
    });
};

const CardModal: React.FC<CardModalProps> = ({ card, loading, onClose }) => {
    const [activeVariant, setActiveVariant] = useState(0);
    const [showBackImage, setShowBackImage] = useState(false);
    const [filteredVariants, setFilteredVariants] = useState<CardVariant[]>([]);

    useEffect(() => {
        if (card && card.cardVariants) {
            const filtered = filterVariants(card.cardVariants);
            setFilteredVariants(filtered);
        }
    }, [card]);

    if (loading) {
        return (
            <Modal show={true} onHide={onClose} dialogClassName="card-modal" centered>
                <Modal.Header closeButton className="bg-space-dark-blue text-space-white">
                    <Modal.Title>Loading...</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center bg-body-bg">
                    <Spinner animation="border" variant="primary" />
                </Modal.Body>
            </Modal>
        );
    }

    if (!card) {
        return (
            <Modal show={true} onHide={onClose} dialogClassName="card-modal" centered>
                <Modal.Header closeButton className="bg-space-dark-blue text-space-white">
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-body-bg text-text">
                    <p>No card data available</p>
                </Modal.Body>
                <Modal.Footer className="bg-space-dark-blue">
                    <Button variant="outline-space-gold" onClick={onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const isLeader = card.cardType === CardTypeEnum.Leader;
    const isBase = card.cardType === CardTypeEnum.Base;
    const hasBackImage = isLeader;

    const renderCardImage = (variant: CardVariant) => (
        <div className={`card-flip-container ${showBackImage ? 'flipped' : ''} ${isLeader ? 'leader' : isBase ? 'base' : 'other'} mobile-friendly`}>
            <div className="card-flipper">
                <div className="card-front">
                    <CardImage
                        image={variant.image}
                        showBack={false}
                        name={card.name}
                        isLandscape={isLeader || isBase}
                    />
                </div>
                {hasBackImage && (
                    <div className="card-back">
                        <CardImage
                            image={variant.image}
                            showBack={true}
                            name={card.name}
                            isLandscape={false}
                        />
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <Modal show={true} onHide={onClose} dialogClassName="card-modal" centered>
            <Modal.Header closeButton closeVariant="white" className="bg-space-dark-blue text-space-white">
                <Modal.Title>{card.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {filteredVariants.length > 1 ? (
                    <Tabs
                        activeKey={activeVariant}
                        onSelect={(k) => setActiveVariant(Number(k))}
                        className="mb-3 card-variant-tabs"
                    >
                        {filteredVariants.map((variant, index) => (
                            <Tab
                                key={index}
                                eventKey={index}
                                title={capitalize(variant.variantType || '')}
                            >
                                {renderCardImage(variant)}
                            </Tab>
                        ))}
                    </Tabs>
                ) : (
                    renderCardImage(filteredVariants[0] || card.cardVariants?.[0])
                )}
                {hasBackImage && (
                    <Button
                        variant="outline-space-blue"
                        onClick={() => setShowBackImage(!showBackImage)}
                        className="mt-3 toggle-image-btn"
                    >
                        {showBackImage ? 'Show Front Image' : 'Show Back Image'}
                    </Button>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CardModal;
