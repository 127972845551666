import React, { useState, useEffect } from 'react';
import { Form, Spinner, Alert } from 'react-bootstrap';
import { useGetUserSharingSettingsQuery, useUpdateCollectionSharingMutation, useUpdateWantListSharingMutation } from '../graphql/generated/graphql';
import Skeleton from './layout/Skeleton';

const SharingSettings: React.FC = () => {
    const { data, loading, error, refetch } = useGetUserSharingSettingsQuery();
    const [updateCollectionSharing] = useUpdateCollectionSharingMutation();
    const [updateWantListSharing] = useUpdateWantListSharingMutation();

    const [collectionShared, setCollectionShared] = useState(false);
    const [wantListShared, setWantListShared] = useState(false);
    const [updatingCollection, setUpdatingCollection] = useState(false);
    const [updatingWantList, setUpdatingWantList] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (data?.userSharingSettings) {
            setCollectionShared(data.userSharingSettings.collectionSharedWithAll);
            setWantListShared(data.userSharingSettings.wantListSharedWithAll);
        }
    }, [data]);

    if (loading) {
        return (
            <Form>
                <Skeleton height="24px" width="70%" className="mb-3" />
                <Skeleton height="24px" width="70%" className="mb-3" />
            </Form>
        );
    }
    if (error) return <div>Error loading sharing settings</div>;

    const handleSharingChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
        updateMutation: typeof updateCollectionSharing | typeof updateWantListSharing,
        setSharedState: React.Dispatch<React.SetStateAction<boolean>>,
        setUpdatingState: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        const newValue = event.target.checked;
        setUpdatingState(true);
        setErrorMessage(null);
        try {
            const result = await updateMutation({
                variables: {
                    input: { shareWithAll: newValue }
                }
            });
            if (result.data?.updateCollectionSharing?.success || result.data?.updateWantListSharing?.success) {
                setSharedState(newValue);
                refetch();
            } else {
                const errors = result.data?.updateCollectionSharing?.errors || result.data?.updateWantListSharing?.errors;
                if (errors && errors.length > 0) {
                    setErrorMessage(errors.map(e => e.message).join(', '));
                } else {
                    setErrorMessage('An unknown error occurred');
                }
                setSharedState(!newValue); // Revert the checkbox state
            }
        } catch (error) {
            console.error('Error updating sharing settings:', error);
            setErrorMessage('An error occurred while updating sharing settings');
            setSharedState(!newValue); // Revert the checkbox state
        } finally {
            setUpdatingState(false);
        }
    };

    return (
        <Form>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    id="collection-sharing"
                    label={
                        <>
                            Make my collection public
                            {updatingCollection && <Spinner animation="border" size="sm" className="ms-2" />}
                        </>
                    }
                    checked={collectionShared}
                    onChange={(e) => handleSharingChange(e, updateCollectionSharing, setCollectionShared, setUpdatingCollection)}
                    disabled={updatingCollection}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    id="want-list-sharing"
                    label={
                        <>
                            Make my want list public
                            {updatingWantList && <Spinner animation="border" size="sm" className="ms-2" />}
                        </>
                    }
                    checked={wantListShared}
                    onChange={(e) => handleSharingChange(e, updateWantListSharing, setWantListShared, setUpdatingWantList)}
                    disabled={updatingWantList}
                />
            </Form.Group>
        </Form>
    );
};

export default SharingSettings;
