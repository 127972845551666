import {
    faHome,
    faLayerGroup,
    faBook,
    faExchange,
    faUser,
    faCog,
    faSearch,
    faEye
} from '@fortawesome/free-solid-svg-icons';

export const Icons = {
    Home: faHome,
    CardSets: faLayerGroup,
    Collection: faBook,
    Trading: faExchange,
    Profile: faUser,
    Settings: faCog,
    Search: faSearch,
    View: faEye
};
