import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { useGetUserWantListQuery, useUpdateUserVariantInventoryMutation, UserWantItem, VariantCount, useCreateOfferMutation } from '../../../graphql/generated/graphql';
import WantCard from './WantCard';
import WantCardEdit from './WantCardEdit';
import ErrorDisplay from '../../layout/ErrorDisplay';
import LoadingSkeleton from '../../layout/Skeleton';
import { useUser } from '../../../hooks/useUser';
import OfferBanner from './OfferBanner';
import OfferForm from '../../trade/OfferForm';
import EmptyWantList from './EmptyWantList';
import Modal from 'react-bootstrap/Modal';

const WantList: React.FC = () => {
    const { handle } = useParams<{ handle: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, user } = useUser();
    const [isEditMode, setIsEditMode] = useState(false);
    const { data, loading, error } = useGetUserWantListQuery({
        variables: { userHandle: handle },
    });
    const [updateUserVariantInventory] = useUpdateUserVariantInventoryMutation();
    const [wantList, setWantList] = useState<UserWantItem[]>([]);
    const [createOffer, { loading: isCreatingOffer }] = useCreateOfferMutation();
    const [showOfferModal, setShowOfferModal] = useState(false);

    useEffect(() => {
        if (data?.userWantList?.nodes) {
            setWantList(data.userWantList.nodes as UserWantItem[]);
        }
    }, [data]);

    useEffect(() => {
        setIsEditMode(location.pathname.endsWith('/edit'));
    }, [location.pathname]);

    useEffect(() => {
        if (!isAuthenticated && (handle === 'my' || !handle)) {
            navigate('/');
        }
    }, [isAuthenticated, handle, navigate]);

    const handleCreateOffer = () => {
        setShowOfferModal(true);
    };

    const handleCloseOfferModal = () => {
        setShowOfferModal(false);
    };

    const handlePublish = async (cardId: string, variantId: string, newCount: number) => {
        try {
            const result = await updateUserVariantInventory({
                variables: {
                    variantId: parseInt(variantId, 10),
                    field: 'published_wanted',
                    newValue: newCount
                },
            });

            if (result.data?.updateUserVariantInventory?.userVariantInventory) {
                setWantList(prevWantList => prevWantList.map(card =>
                    card.id === cardId
                        ? {
                            ...card,
                            variantCounts: card.variantCounts.map((vc: VariantCount) =>
                                vc.variantId === variantId
                                    ? { ...vc, publishedCount: newCount }
                                    : vc
                            )
                        }
                        : card
                ));
                return newCount;
            }
            return -1;
        } catch (error) {
            console.error('Error publishing want count:', error);
            return -1;
        }
    };

    if (loading) return <LoadingSkeleton />;
    if (error) return <ErrorDisplay message={error.message} />;

    const isOwnWantList = !handle || handle === 'my' || (isAuthenticated && user?.handle === handle);

    if (!wantList || wantList.length === 0) {
        return <EmptyWantList isOwnProfile={isOwnWantList} user={handle} />;
    }

    const displayHandle = isOwnWantList ? 'My' : `${handle}'s`;

    const toggleEditMode = () => {
        const newMode = !isEditMode;
        setIsEditMode(newMode);
        navigate(newMode ? `/collections/my/want/edit` : `/collections/my/want`);
    };

    const groupedWantList = wantList.reduce((acc, wantedCard) => {
        const setName = wantedCard.cardDetails.cardSetName;
        const setId = wantedCard.cardDetails.cardSetId;
        if (!acc[setId]) {
            acc[setId] = { name: setName, cards: [] };
        }
        acc[setId].cards.push(wantedCard);
        return acc;
    }, {} as Record<string, { name: string, cards: UserWantItem[] }>);

    const sortedCardSets = Object.entries(groupedWantList).sort(([aId], [bId]) =>
        parseInt(aId) - parseInt(bId)
    );

    return (
        <Container className="mt-4 want-list-container">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h1>{displayHandle} Want List</h1>
                {isOwnWantList && (
                    <Button
                        onClick={toggleEditMode}
                        disabled={loading}
                        variant="outline-space-blue"
                        className="btn-icon"
                        title={isEditMode ? "View Mode" : "Edit Mode"}
                    >
                        <FontAwesomeIcon icon={isEditMode ? faEye : faPencilAlt} />
                    </Button>
                )}
            </div>

            {!isOwnWantList && (
                <OfferBanner onCreateOffer={handleCreateOffer} />
            )}

            <Modal show={showOfferModal} onHide={handleCloseOfferModal} dialogClassName="offer-modal" centered>
                <Modal.Header closeButton closeVariant="white" className="bg-space-dark-blue text-space-white">
                    <Modal.Title>Create Offer for {handle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OfferForm
                        buyerHandle={handle || ''}
                        wantListItems={wantList}
                        isSubmitting={isCreatingOffer}
                        onSubmit={async (offerData) => {
                            try {
                                const { data } = await createOffer({
                                    variables: {
                                        input: {
                                            buyerHandle: offerData.buyerHandle,
                                            offerItems: offerData.offerItems.map(item => ({
                                                variantId: item.variantId,
                                                quantity: item.quantity,
                                                price: item.price
                                            }))
                                        }
                                    }
                                });

                                if (data?.createOffer?.offer) {
                                    console.log('Offer created successfully:', data.createOffer.offer);
                                    handleCloseOfferModal();
                                    // You might want to update the UI or show a success message here
                                } else if (data?.createOffer?.errors) {
                                    console.error('Offer creation failed:', data.createOffer.errors);
                                    // Handle errors, perhaps show an error message to the user
                                }
                            } catch (error) {
                                console.error('Error submitting offer:', error);
                                // Handle any network or other errors
                            }
                        }}
                    />
                </Modal.Body>
            </Modal>

            {sortedCardSets.map(([setId, { name: setName, cards }]) => (
                <div key={setId} className="mb-5">
                    <h2 className="h5 set-name-header pb-2 mb-3">{setName}</h2>
                    <div className="want-list-grid">
                        {cards.map((wantedCard) => (
                            isEditMode ? (
                                <WantCardEdit
                                    key={wantedCard.id}
                                    wantedCard={wantedCard}
                                    onPublish={handlePublish}
                                />
                            ) : (
                                <WantCard
                                    key={wantedCard.id}
                                    wantedCard={wantedCard}
                                    isOwnWantList={isOwnWantList}
                                />
                            )
                        ))}
                    </div>
                </div>
            ))}
        </Container>
    );
};

export default WantList;
