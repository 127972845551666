import React from 'react';
import Select from 'react-select';
import { AspectTypeEnum } from '../../graphql/generated/graphql';

interface AspectFilterProps {
    aspects: AspectTypeEnum[];
    selected: AspectTypeEnum[];
    matchAll: boolean;
    onChange: (selected: AspectTypeEnum[], matchAll: boolean) => void;
    disabled?: boolean;
}

const AspectFilter: React.FC<AspectFilterProps> = ({ aspects, selected, matchAll, onChange, disabled }) => {
    const options = aspects.map(aspect => ({ value: aspect, label: aspect }));
    const value = selected.map(aspect => ({ value: aspect, label: aspect }));

    const handleAspectChange = (selectedOptions: readonly { value: string; label: string }[]) => {
        const selectedAspects = selectedOptions.map(option => option.value as AspectTypeEnum);
        onChange(selectedAspects, matchAll);
    };

    const handleMatchAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(selected, event.target.checked);
    };

    return (
        <div className="card-filters__section">
            <label className="card-filters__section-title">Aspects</label>
            <Select
                isMulti
                options={options}
                value={value}
                onChange={handleAspectChange}
                placeholder="Filter by aspects..."
                className="card-filters__select"
                classNamePrefix="card-filters__select"
                isDisabled={disabled}
            />
            <div className="card-filters__checkbox">
                <input
                    type="checkbox"
                    id="matchAllAspects"
                    checked={matchAll}
                    onChange={handleMatchAllChange}
                    disabled={disabled}
                />
                <label htmlFor="matchAllAspects">Match all selected aspects</label>
            </div>
        </div>
    );
};

export default AspectFilter;
