import React, { useState } from 'react';
import { Container, Card, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import OfferList from './trade/OfferList';
import SharedWantLists from './trade/SharedWantLists';
import { useUser } from '../hooks/useUser';
import { OfferTypeEnum } from '../graphql/generated/graphql';
import HomeBannerCard from './HomeBannerCard';

enum TradingHubTab {
    ReceivedOffers = 'ReceivedOffers',
    SentOffers = 'SentOffers',
    SharedWantLists = 'SharedWantLists',
}

const TradingHub: React.FC = () => {
    const { isAuthenticated, hasHandle } = useUser();
    const [activeTab, setActiveTab] = useState<TradingHubTab>(TradingHubTab.SharedWantLists);

    const canTrade = isAuthenticated && hasHandle;

    const renderTab = (tab: TradingHubTab, label: string) => {
        const isActive = activeTab === tab;
        const isDisabled = tab !== TradingHubTab.SharedWantLists && !canTrade;

        return (
            <Nav.Item>
                <Nav.Link
                    className={`custom-tab ${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
                    onClick={() => !isDisabled && setActiveTab(tab)}
                    disabled={isDisabled}
                >
                    {label}
                </Nav.Link>
            </Nav.Item>
        );
    };

    return (
        <Container className="mt-5">
            <h1 className="mb-4 text-center">Trading Hub</h1>
            {!canTrade && (
                <HomeBannerCard>
                    <Card.Text className="text-white">
                        {!isAuthenticated ? (
                            <>
                                <FontAwesomeIcon icon={faSignInAlt} className="me-2" />
                                <Link to="/login" className="text-space-blue hover-text-space-gold">Log in</Link> to send or receive offers.
                            </>
                        ) : !hasHandle && (
                            <>
                                <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                                <Link to="/profile" className="text-space-blue hover-text-space-gold">Set up your profile</Link> to start trading.
                            </>
                        )}
                    </Card.Text>
                </HomeBannerCard>
            )}
            <Nav variant="tabs" className="custom-tabs mb-3">
                {renderTab(TradingHubTab.SharedWantLists, 'Shared Want Lists')}
                {renderTab(TradingHubTab.ReceivedOffers, 'Received Offers')}
                {renderTab(TradingHubTab.SentOffers, 'Sent Offers')}
            </Nav>
            {activeTab === TradingHubTab.ReceivedOffers && canTrade && <OfferList type={OfferTypeEnum.Received} />}
            {activeTab === TradingHubTab.SentOffers && canTrade && <OfferList type={OfferTypeEnum.Sent} />}
            {activeTab === TradingHubTab.SharedWantLists && <SharedWantLists />}
        </Container>
    );
};

export default TradingHub;
