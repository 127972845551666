import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faGlobe } from '@fortawesome/free-solid-svg-icons';
import CardHover from '../../card/CardHover';
import { UserWantItem, CardTypeEnum } from '../../../graphql/generated/graphql';

interface WantCardEditProps {
    wantedCard: UserWantItem;
    onPublish: (cardId: string, variantId: string, newCount: number) => Promise<number>;
}

const WantCardEdit: React.FC<WantCardEditProps> = ({ wantedCard, onPublish }) => {
    const [publishedCounts, setPublishedCounts] = useState<Record<string, number>>(
        Object.fromEntries(wantedCard.variantCounts.map(vc => [vc.variantId, vc.publishedCount]))
    );

    const getAspectClasses = (aspects: string[] | null | undefined): string => {
        if (!aspects || aspects.length === 0) return 'aspect-none';
        const priorityAspects = aspects.filter(aspect => !['villainy', 'heroism'].includes(aspect.toLowerCase()));
        if (priorityAspects.length > 0) {
            return `aspect-${priorityAspects[0].toLowerCase()}`;
        }
        return `aspect-${aspects[0].toLowerCase()}`;
    };

    const isLandscape = wantedCard.cardDetails.cardType === CardTypeEnum.Leader || wantedCard.cardDetails.cardType === CardTypeEnum.Base;

    const handlePublishedCountChange = async (variantId: string, newCount: number) => {
        const serverCount = await onPublish(wantedCard.id, variantId, newCount);
        setPublishedCounts(prev => ({ ...prev, [variantId]: serverCount }));
    };

    return (
        <CardHover cardId={wantedCard.id} showImageOnly={true}>
            <div className={`want-card want-card-edit ${getAspectClasses(wantedCard.cardDetails.aspects)} ${isLandscape ? 'landscape' : ''}`}>
                <div className="want-card-header">
                    <span className="want-card-number">{wantedCard.cardDetails.fullCardNumber}</span>
                    <h3 className="want-card-name">{wantedCard.cardDetails.name}</h3>
                </div>
                <div className="want-card-variants">
                    {wantedCard.variantCounts.map((variant) => (
                        <div key={variant.variantId} className="want-card-variant">
                            <span className="variant-type">{variant.variantType}</span>
                            <div className="want-counts">
                                <div className="want" title="Wanted">
                                    <FontAwesomeIcon icon={faHeart} className="icon-heart" />
                                    <span>{variant.count}</span>
                                </div>
                                <div className="published-want" title="Published">
                                    <FontAwesomeIcon icon={faGlobe} className="icon-globe" />
                                    <input
                                        type="number"
                                        value={publishedCounts[variant.variantId]}
                                        onChange={(e) => handlePublishedCountChange(variant.variantId, parseInt(e.target.value, 10))}
                                        min="0"
                                        max={variant.count}
                                        className="published-count-input"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </CardHover>
    );
};

export default WantCardEdit;
