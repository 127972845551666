import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card as CardType, useGetCardQuery, CardTypeEnum } from '../../graphql/generated/graphql';
import CardImage from './CardImage';
import CardModal from './CardModal';

interface CardImageViewProps {
    cards: CardType[];
}

const CardImageView: React.FC<CardImageViewProps> = ({ cards }) => {
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);

    const { data: cardData, loading: cardLoading } = useGetCardQuery({
        variables: { id: selectedCardId || '' },
        skip: !selectedCardId,
    });

    const isLandscape = (card: CardType) =>
        card.cardType === CardTypeEnum.Leader || card.cardType === CardTypeEnum.Base;

    const handleCardClick = (card: CardType) => {
        setSelectedCardId(card.id);
    };

    const handleCloseModal = () => {
        setSelectedCardId(null);
    };

    return (
        <>
            <Row className="g-2 card-image-grid">
                {cards.map((card) => (
                    <Col
                        key={card.id}
                        xs={isLandscape(card) ? 6 : 4}
                        sm={isLandscape(card) ? 4 : 3}
                        md={isLandscape(card) ? 3 : 2}
                        lg={isLandscape(card) ? 3 : 2}
                        xl={isLandscape(card) ? 2 : 1}
                        className="mb-2"
                    >
                        <div onClick={() => handleCardClick(card)} className="card-image-clickable">
                            <CardImage
                                image={card.image}
                                name={card.name}
                                isLandscape={isLandscape(card)}
                            />
                        </div>
                    </Col>
                ))}
            </Row>
            {selectedCardId && (
                <CardModal
                    card={cardData?.card}
                    loading={cardLoading}
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

export default CardImageView;
