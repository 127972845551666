import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetCardSetsQuery } from '../graphql/generated/graphql';
import { getFormattedImageUrl } from '../utils/imageUtils';
import { useAuth } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import HomeBannerCard from './HomeBannerCard';

const Home: React.FC = () => {
    const { loading, error, data } = useGetCardSetsQuery();
    const { isAuthenticated } = useAuth();

    const renderCardSetSkeleton = () => (
        <Col lg={4} className="mb-4">
            <Card className="h-100 shadow-sm">
                <Card.Body>
                    <div className="skeleton-image mb-3"></div>
                    <div className="skeleton-text mb-2"></div>
                    <div className="skeleton-text mb-2"></div>
                    <div className="skeleton-text"></div>
                </Card.Body>
            </Card>
        </Col>
    );

    return (
        <Container>
            <HomeBannerCard>
                {!isAuthenticated ? (
                    <>
                        <Card.Title as="h3" className="text-space-gold">Start Your Collection Journey!</Card.Title>
                        <Card.Text>
                            Want to track your card collection, share your want list, and trade with the community?{' '}
                            <Link to="/login" className="text-space-blue hover-text-space-gold">Log in</Link> to get started!
                        </Card.Text>
                    </>
                ) : (
                    <>
                        <Card.Title as="h3" className="text-space-gold">
                            <FontAwesomeIcon icon={faBook} className="me-2" />My Collection
                        </Card.Title>
                        <Card.Text>
                            View and manage your personal card collection.
                        </Card.Text>
                        <Link to="/collections/my" className="btn btn-primary mt-3">Go to My Collection</Link>
                    </>
                )}
            </HomeBannerCard>

            <Row>
                <Col>
                    <h2 className="section-title mb-4">Explore Card Sets</h2>
                    <Row>
                        {loading ? (
                            <>
                                {renderCardSetSkeleton()}
                                {renderCardSetSkeleton()}
                                {renderCardSetSkeleton()}
                            </>
                        ) : error ? (
                            <Col lg={12}>
                                <p>Error: {error.message}</p>
                            </Col>
                        ) : (
                            data?.cardSets?.nodes?.slice(0, 3).map((set) => (
                                <Col lg={4} key={set?.id} className="mb-4">
                                    <Card className="h-100 shadow-sm hover-card">
                                        <Card.Img variant="top"
                                            src={getFormattedImageUrl(set?.imageUrl, 300)}
                                            className="card-img-fixed-height" />
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Title>{set?.name}</Card.Title>
                                            <Card.Text className="flex-grow-1">{set?.description}</Card.Text>
                                            <Link to={`/card-sets/${set?.code}`} className="btn btn-outline-primary mt-auto">View Set</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
