import React from "react"
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import CardSets from "../components/card_set/CardSets";
import CardSetView from "../components/card_set/CardSetView";
import CardPageView from "../components/card/CardPageView";
import TradingHub from "../components/TradingHub";
import UserProfile from "../components/UserProfile";
import Login from "../components/auth/Login";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import CollectionView from "../components/collection/CollectionView";
import WantList from "../components/collection/wanted/WantList";
import UserSettings from "../components/UserSettings";

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/card-sets" element={<CardSets />} />
        <Route path="/card-sets/:cardSetCode" element={<CardSetView />} />
        <Route path="/cards/:id" element={<CardPageView />} />
        <Route path="/collections/:handle" element={<CollectionView />} />
        <Route path="/collections/:handle/want" element={<WantList />} />
        <Route path="/trading" element={<TradingHub />} />
        <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/settings" element={<UserSettings />} />
            <Route path="/collections/my" element={<CollectionView />} />
            <Route path="/collections/my/edit" element={<CollectionView />} />
            <Route path="/collections/my/want" element={<WantList />} />
            <Route path="/collections/my/want/edit" element={<WantList />} />
        </Route>
    </Routes>
);

export default AppRoutes;
