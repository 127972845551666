import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { useGetSharedWantListsQuery } from '../../graphql/generated/graphql';

const SharedWantLists: React.FC = () => {
    const { data, loading, error } = useGetSharedWantListsQuery();

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="space-table-container">
            <table className="space-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Visibility</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.sharedWantLists?.nodes?.map((sharedWantList) => (
                        sharedWantList && (
                            <tr key={sharedWantList.id}>
                                <td>{sharedWantList.user.handle}</td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={sharedWantList.publiclyShared ? faGlobeAmericas : faUserFriends}
                                        title={sharedWantList.publiclyShared ? "Public" : "Private"}
                                        className={`me-2 ${sharedWantList.publiclyShared ? 'text-success' : 'text-primary'}`}
                                    />
                                    {sharedWantList.publiclyShared ? "Public" : "Private"}
                                </td>
                                <td>
                                    <Link to={`/collections/${sharedWantList.user.handle}/want`} className="space-table-link">
                                        View Want List
                                    </Link>
                                </td>
                            </tr>
                        )))}
                </tbody>
            </table>
        </div>
    );
};

export default SharedWantLists;
