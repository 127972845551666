import { CardVariantTypeEnum } from '../graphql/generated/graphql';

export function getCardVariantTypeDisplay(variantType: CardVariantTypeEnum): string {
    switch (variantType) {
        case CardVariantTypeEnum.Standard:
            return 'Standard';
        case CardVariantTypeEnum.Foil:
            return 'Foil';
        case CardVariantTypeEnum.Hyperspace:
            return 'Hyperspace';
        case CardVariantTypeEnum.HyperspaceFoil:
            return 'Hyperspace Foil';
        default:
            return 'Unknown';
    }
}

export const orderedVariantTypes: CardVariantTypeEnum[] = [
    CardVariantTypeEnum.Standard,
    CardVariantTypeEnum.Foil,
    CardVariantTypeEnum.Hyperspace,
    CardVariantTypeEnum.HyperspaceFoil
];


