import React, { useState, useMemo } from 'react';
import { Card, useGetCardQuery } from '../../graphql/generated/graphql';
import CardHover from './CardHover';
import CardModal from './CardModal';

interface CardTableProps {
    cards: Card[];
}

const CardTable: React.FC<CardTableProps> = ({ cards }) => {
    const [sortColumn, setSortColumn] = useState<keyof Card>('standardCardNumber');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);

    const handleCardClick = (card: CardType) => {
        setSelectedCardId(card.id);
    };

    const handleCloseModal = () => {
        setSelectedCardId(null);
    };

    const { data: cardData, loading: cardLoading } = useGetCardQuery({
        variables: { id: selectedCardId || '' },
        skip: !selectedCardId,
    });

    const handleSort = (column: keyof Card) => {
        if (column === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const renderSortIcon = (column: keyof Card) => {
        if (column !== sortColumn) return null;
        return sortDirection === 'asc' ? '▲' : '▼';
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const compareValues = (a: any, b: any, direction: 'asc' | 'desc'): number => {
        if (Array.isArray(a) && Array.isArray(b)) {
            const aString = a.join(',');
            const bString = b.join(',');
            return direction === 'asc' ? aString.localeCompare(bString) : bString.localeCompare(aString);
        }
        if (typeof a === 'string' && typeof b === 'string') {
            return direction === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
        }
        return direction === 'asc' ? (a > b ? 1 : -1) : (b > a ? 1 : -1);
    };

    const sortedCards = useMemo(() => {
        return [...cards].sort((a, b) => {
            const aValue = a[sortColumn] ?? '';
            const bValue = b[sortColumn] ?? '';
            return compareValues(aValue, bValue, sortDirection);
        });
    }, [cards, sortColumn, sortDirection]);

    return (
        <div className="space-table-container">
            <table className="space-table">
                <thead className="space-table-header">
                    <tr>
                        {['ID', 'Name', 'Rarity', 'Cost', 'Power', 'HP', 'Type', 'Arena', 'Aspects', 'Traits', 'Keywords'].map((header, index) => (
                            <th key={index} onClick={() => handleSort(header.toLowerCase() as keyof Card)} role="button" tabIndex={0}>
                                {header} {renderSortIcon(header.toLowerCase() as keyof Card)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedCards.map((card) => (
                        <tr key={card.id} onClick={() => handleCardClick(card)} className="card-row-clickable">
                            <td>{card.standardCardNumber}</td>
                            <td>
                                <CardHover cardId={card.id} showImageOnly={true}>
                                    <span className="space-table-link">{card.name}</span>
                                </CardHover>
                            </td>
                            <td>{card.rarity}</td>
                            <td>{card.cost}</td>
                            <td>{card.power}</td>
                            <td>{card.hp}</td>
                            <td>{card.cardType}</td>
                            <td>{card.arena}</td>
                            <td>{card.aspects?.join(', ')}</td>
                            <td>{card.traits?.join(', ')}</td>
                            <td>{card.keywords?.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedCardId && (
                <CardModal
                    card={cardData?.card}
                    loading={cardLoading}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default CardTable;
