import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../constants/icons';
import { useAuth } from '../../contexts/AuthContext';

const Footer: React.FC = () => {
    const { isAuthenticated } = useAuth();

    const links = [
        { to: "/", icon: Icons.Home, text: "Home" },
        { to: "/card-sets", icon: Icons.CardSets, text: "Card Sets" },
        ...(isAuthenticated ? [
            { to: "/collections/my", icon: Icons.Collection, text: "My Collection" },
            { to: "/collections/my/want", icon: Icons.Collection, text: "My Want List" },
            { to: "/trading", icon: Icons.Trading, text: "Trading" },
            { to: "/profile", icon: Icons.Profile, text: "Profile" },
            { to: "/settings", icon: Icons.Settings, text: "Settings" }
        ] : [
            { to: "/trading", icon: Icons.Trading, text: "Trading" }
        ])
    ];

    return (
        <footer className="py-4 mt-auto">
            <Container>
                <Row>
                    <Col>
                        <h2 className="mb-4 section-title">Quick Links</h2>
                    </Col>
                </Row>
                <Row className="mb-4">
                    {links.map((link, index) => (
                        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-2">
                            <Link to={link.to} className="btn btn-outline-primary w-100">
                                <FontAwesomeIcon icon={link.icon} className="me-2" />
                                {link.text}
                            </Link>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col className="text-center mt-3 small">
                        <p>© & ™ Lucasfilm Ltd. | © 2023 Fantasy Flight Publishing, Inc.</p>
                        <p>This is a fan-made application and is not affiliated with Lucasfilm Ltd., Fantasy Flight Games, or Disney.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
